import { $http } from "@/plugins/api.js";

const getDefaultState = () => {
  return {
    rules: [],
    serverTotal: 0,
  };
};

// initial state
const state = getDefaultState();
const getters = {
  geoBlockingRules: (state) => state.rules,
  serverTotal: (state) => state.serverTotal,
};

const actions = {
  clearCache({ commit }) {
    commit("resetState");
  },

  async getGeoblockingRules(
    { commit },
    {
      tier,
      id,
      results = 10,
      page = 1,
      sortBy = "created_at",
      sortDir = "desc",
      filter = null,
    }
  ) {
    let requestURL =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/geo-rules`
        : `/restapi/geo-rules`;

    if (results) requestURL += `?results=${results}`;
    if (page) requestURL += `&page=${page}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    if (filter) requestURL += `&country=${filter}`;

    const res = await $http.get(requestURL);

    commit("setRules", res.data.data);
    commit("setServerTotal", res.data.total);
  },

  async batchDeleteRules({ commit }, { tier, id, rules }) {
    const entries = [];

    for (const rule of rules) {
      const key = `${rule.iso}`;
      const value = {
        method: "delete",
        path:
          tier !== null && id !== null
            ? `${tier}/${id}/geo-rules/${rule.iso}`
            : `/geo-rules/${rule.iso}`,
      };

      entries.push([key, value]);
    }

    const res = await $http.post("/restapi/batch", Object.fromEntries(entries));
    const isoCodes = Object.keys(res.data).map((item) => item);

    commit("deleteRules", isoCodes);
  },

  async updateGeoBlockingRule({ commit }, { tier, id, iso, form }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/geo-rules/${iso}`
        : `/restapi/geo-rules/${iso}`;
    const res = await $http.put(url, form);

    commit("updateRule", res.data);
  },
  async createGeoRule({ commit }, { tier, id, rule }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/geo-rules`
        : `/restapi/geo-rules`;
    const res = await $http.post(url, rule);

    commit("addRule", res.data);
  },

  clearList({ commit }) {
    commit("setRules", []);
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setRules: (state, data) => (state.rules = data),
  addRule: (state, data) => {
    state.rules.push(data);
    state.serverTotal = state.serverTotal + 1;
  },
  deleteRules(state, isoCodes) {
    for (const iso of isoCodes) {
      state.rules = state.rules.filter((rule) => rule.iso !== iso);
    }
    state.serverTotal = state.serverTotal - 1;
  },

  updateRule(state, data) {
    state.rules.forEach((rule, index) => {
      if (rule.iso === data.iso) {
        state.rules[`${index}`].comment = data.comment;
      }
    });
  },
  setServerTotal: (state, payload) => (state.serverTotal = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
