import { ACCOUNT_TYPE } from "@/constants";
import Security from "@/views/Profile/Security";

const systemRoutes = [
  {
    path: "",
    redirect: {
      name: "system-overview",
    },
    meta: {
      authorize: [ACCOUNT_TYPE.SYSTEM, ACCOUNT_TYPE.SALES],
    },
  },
  {
    path: "overview",
    name: "system-overview",
    component: () => import("@/views/System/Overview.vue"),
  },
  {
    path: "overview/:id",
    name: "system-msp-edit",
    component: () => import("@/views/System/Msps/index"),
  },
  {
    path: "profile",
    name: "profile",
    component: Security,
  },
  {
    path: "msp/profile",
    component: Security,
  },
  {
    path: "msp/customer/profile",
    component: Security,
  },
  {
    path: "msp/customer/domain/profile",
    component: Security,
  },
  {
    path: "msp/customer/domain/user/profile",
    component: Security,
  },
  {
    path: "msp/history",
    redirect: {
      name: "msp-history",
    },
  },
  {
    path: "msp/history/message_overview",
    redirect: {
      name: "msp-history",
    },
  },
  {
    path: "msp/history/message_overview/:id",
    redirect: {
      name: "msp-history-message-overview-details",
    },
  },
  {
    path: "msp/quarantine",
    redirect: {
      name: "msp-quarantine",
    },
  },
  {
    path: "msp/quarantine/message_overview",
    redirect: {
      name: "msp-quarantine",
    },
  },
  {
    path: "msp/quarantine/message_overview/:id",
    redirect: {
      name: "msp-quarantine-message-overview-details",
    },
  },
  {
    path: "filtering",
    redirect: {
      name: "system-block-allow-list",
    },
    component: () => import("@/views/System/Filtering"),
    children: [
      {
        path: "allow_block_list",
        name: "system-block-allow-list",
        redirect: {
          name: "system-block-allow-list-ips",
        },
        component: () => import("@/views/System/Filtering/BlockAllowList"),
        children: [
          {
            path: "IPs",
            name: "system-block-allow-list-ips",
            component: () =>
              import("@/views/System/Filtering/BlockAllowList/Ips"),
          },
        ],
      },
      {
        path: "allow_block_list/IPs/:id",
        name: "system-block-allow-list-ips-edit",
        component: () =>
          import("@/views/System/Filtering/BlockAllowList/Ips/Edit"),
      },
      {
        path: "pattern_filtering",
        name: "system-filtering-patterns-list",
        component: () => import("@/views/System/Filtering/Patterns/index.vue"),
      },
      {
        path: "pattern_filtering/edit_single_pattern",
        redirect: {
          name: "system-filtering-patterns-list",
        },
      },
      {
        path: "pattern_filtering/edit_multi_pattern",
        redirect: {
          name: "system-filtering-patterns-list",
        },
      },
      {
        path: "pattern_filtering/edit_multi_pattern/:id",
        name: "system-filtering-patterns-edit-multi",
        component: () =>
          import("@/views/System/Filtering/Patterns/Edit/Multi.vue"),
      },
      {
        path: "pattern_filtering/edit_single_pattern/:id",
        name: "system-filtering-patterns-edit-single",
        component: () =>
          import("@/views/System/Filtering/Patterns/Edit/Single.vue"),
      },
    ],
  },
  {
    path: "settings",
    name: "system-settings",
    component: () => import("@/views/System/Settings"),
    redirect: {
      name: "system-settings-administrators",
    },
    children: [
      {
        path: "administrators",
        name: "system-settings-administrators",
        component: () => import("@/views/System/Settings/Administrators"),
      },
      {
        path: "administrators/:id",
        name: "system-settings-administrators-edit",
        component: () => import("@/views/Common/Administrators/Edit"),
      },
    ],
  },
  {
    path: "msp",
    redirect: {
      name: "mspOverview",
    },
  },
  {
    path: "msp/overview",
    redirect: {
      name: "mspOverview",
    },
  },
  {
    path: "msp/settings",
    redirect: {
      name: "msp-settings-administrators",
    },
  },
  {
    path: "msp/filtering",
    redirect: {
      name: "msp-block-allow-list",
    },
  },
  {
    path: "msp/filtering/geoblocking",
    redirect: {
      name: "geoblocking",
    },
  },
  {
    path: "msp/filtering/geoblocking/exemptions",
    redirect: {
      name: "msp-geoblocking-exemptions",
    },
  },
  {
    path: "msp/filtering/geoblocking/rules",
    redirect: {
      name: "msp-geoblocking-rule",
    },
  },
  {
    path: "msp/filtering/link_lock",
    redirect: {
      name: "msp-link-lock",
    },
  },
  {
    path: "msp/filtering/link_lock/exemptions",
    redirect: {
      name: "msp-link-lock-exemptions",
    },
  },
  {
    path: "msp/settings/administrators",
    redirect: {
      name: "msp-settings-administrators",
    },
  },
  {
    path: "msp/filtering/allow_block_list",
    redirect: {
      name: "msp-block-allow-list",
    },
  },
  {
    path: "msp/filtering/allow_block_list/emails",
    redirect: {
      name: "msp-block-allow-list-emails",
    },
  },
  {
    path: "msp/filtering/allow_block_list/ips",
    redirect: {
      name: "msp-block-allow-list-ips",
    },
  },
  {
    path: "msp/filtering/allow_block_list/domains",
    redirect: {
      name: "msp-block-allow-list-domains",
    },
  },
  {
    path: "msp/filtering/pattern_filtering/edit_multi_pattern",
    redirect: {
      name: "msp-filtering-patterns-list",
    },
  },
  {
    path: "msp/filtering/pattern_filtering/edit_single_pattern",
    redirect: {
      name: "msp-filtering-patterns-list",
    },
  },
  {
    path: "msp/filtering/pattern_filtering",
    redirect: {
      name: "msp-filtering-patterns-list",
    },
  },
  {
    path: "msp/customer/domain/filtering/pattern_filtering",
    redirect: {
      name: "domain-filtering-patterns-list",
    },
  },
  {
    path: "msp/customer",
    redirect: {
      name: "customerOver",
    },
  },
  {
    path: "msp/customer/history",
    redirect: {
      name: "customer-history",
    },
  },
  {
    path: "msp/customer/history/message_overview",
    redirect: {
      name: "customer-history",
    },
  },
  {
    path: "msp/customer/history/message_overview/:id",
    redirect: {
      name: "customer-history-message-overview",
    },
  },
  {
    path: "msp/customer/quarantine",
    redirect: {
      name: "customer-quarantine",
    },
  },
  {
    path: "msp/customer/quarantine/message_overview",
    redirect: {
      name: "customer-quarantine",
    },
  },
  {
    path: "msp/customer/quarantine/message_overview/:id",
    redirect: {
      name: "customer-quarantine-message-overview",
    },
  },
  {
    path: "msp/customer/filtering",
    redirect: {
      name: "customer-filtering-attachments",
    },
  },
  {
    path: "msp/customer/filtering/attachments",
    redirect: {
      name: "customer-filtering-attachments",
    },
  },
  {
    path: "msp/customer/filtering/allow_block_list",
    redirect: {
      name: "customer-block-allow-list",
    },
  },
  {
    path: "msp/customer/filtering/allow_block_list/emails",
    redirect: {
      name: "customer-block-allow-list-emails",
    },
  },
  {
    path: "msp/customer/filtering/allow_block_list/domains",
    redirect: {
      name: "customer-block-allow-list-domains",
    },
  },
  {
    path: "msp/customer/filtering/pattern_filtering",
    redirect: {
      name: "customer-filtering-patterns-list",
    },
  },
  {
    path: "msp/customer/filtering/pattern_filtering",
    redirect: {
      name: "customer-filtering-patterns-list",
    },
  },
  {
    path: "msp/customer/filtering/pattern_filtering/edit_single_pattern",
    redirect: {
      name: "customer-filtering-patterns-list",
    },
  },
  {
    path: "msp/customer/filtering/pattern_filtering/edit_multi_pattern",
    redirect: {
      name: "customer-filtering-patterns-list",
    },
  },
  {
    path: "msp/customer/domain/filtering/pattern_filtering/edit_multi_pattern",
    redirect: {
      name: "domain-filtering-patterns-list",
    },
  },
  {
    path: "msp/customer/domain/filtering/pattern_filtering/edit_single_pattern",
    redirect: {
      name: "domain-filtering-patterns-list",
    },
  },
  {
    path: "msp/customer/filtering/geoblocking",
    redirect: {
      name: "customer-geoblocking",
    },
  },
  {
    path: "msp/customer/filtering/geoblocking/exemptions",
    redirect: {
      name: "customer-geoblocking-exemptions",
    },
  },
  {
    path: "msp/customer/filtering/geoblocking/rules",
    redirect: {
      name: "customer-geoblocking-rule",
    },
  },
  {
    path: "msp/customer/filtering/link_lock",
    redirect: {
      name: "customer-link-lock-policy-configuration",
    },
  },
  {
    path: "msp/customer/filtering/link_lock/exemptions",
    redirect: {
      name: "customer-link-lock-exemptions",
    },
  },
  {
    path: "msp/customer/policies",
    redirect: {
      name: "customer-policies-domain-policies",
    },
  },
  {
    path: "msp/customer/policies/domain_policies",
    redirect: {
      name: "customer-policies-domain-policies",
    },
  },
  {
    path: "msp/customer/reporting",
    redirect: {
      name: "customer-reports-scheduled",
    },
  },
  {
    path: "msp/customer/reporting/scheduled",
    redirect: {
      name: "customer-reports-scheduled",
    },
  },
  {
    path: "msp/customer/reporting/archive",
    redirect: {
      name: "customer-reports-archive",
    },
  },
  {
    path: "msp/customer/reporting/on-demand",
    redirect: {
      name: "customer-reports-on-demand",
    },
  },
  {
    path: "msp/customer/settings",
    redirect: {
      name: "customer-settings-administrators",
    },
  },
  {
    path: "msp/customer/settings/administrators",
    redirect: {
      name: "customer-settings-administrators",
    },
  },
  {
    path: "msp/customer/domain",
    redirect: {
      name: "domain-overview",
    },
  },
  {
    path: "msp/customer/domain/anti-spoofing",
    redirect: {
      name: "anti-spoofing",
    },
  },
  {
    path: "msp/customer/domain/history",
    redirect: {
      name: "domain-history",
    },
  },
  {
    path: "msp/customer/domain/history/message_overview",
    redirect: {
      name: "domain-history",
    },
  },
  {
    path: "msp/customer/domain/history/message_overview/:id",
    redirect: {
      name: "domain-history-message-overview",
    },
  },
  {
    path: "msp/customer/domain/quarantine",
    redirect: {
      name: "domain-quarantine",
    },
  },
  {
    path: "msp/customer/domain/quarantine/message_overview",
    redirect: {
      name: "domain-quarantine",
    },
  },
  {
    path: "msp/customer/domain/quarantine/message_overview/:id",
    redirect: {
      name: "domain-quarantine-message-overview",
    },
  },
  {
    path: "msp/customer/domain/filtering",
    redirect: {
      name: "domain-filtering-attachments",
    },
  },
  {
    path: "msp/customer/domain/filtering/attachments",
    redirect: {
      name: "domain-filtering-attachments",
    },
  },
  {
    path: "msp/customer/domain/filtering/allow_block_list",
    redirect: {
      name: "domain-block-allow-list",
    },
  },
  {
    path: "msp/customer/domain/filtering/allow_block_list/emails",
    redirect: {
      name: "domain-block-allow-list-emails",
    },
  },
  {
    path: "msp/customer/domain/filtering/allow_block_list/domains",
    redirect: {
      name: "domain-block-allow-list-domains",
    },
  },
  {
    path: "msp/customer/domain/filtering/geoblocking",
    redirect: {
      name: "domain-geoblocking",
    },
  },
  {
    path: "msp/customer/domain/filtering/geoblocking/exemptions",
    redirect: {
      name: "domain-geoblocking-exemptions",
    },
  },
  {
    path: "msp/customer/domain/filtering/geoblocking/rules",
    redirect: {
      name: "domain-geoblocking-rule",
    },
  },
  {
    path: "msp/customer/domain/filtering/link_lock",
    redirect: {
      name: "domain-link-lock-policy-configuration",
    },
  },
  {
    path: "msp/customer/domain/filtering/link_lock/exemptions",
    redirect: {
      name: "domain-link-lock-exemptions",
    },
  },
  {
    path: "msp/customer/domain/policies",
    redirect: {
      name: "domain-policies",
    },
  },
  {
    path: "msp/customer/domain/policies/user_policies",
    redirect: {
      name: "domain-policies-user-policies",
    },
  },
  {
    path: "msp/customer/domain/reporting",
    redirect: {
      name: "domain-reports-scheduled",
    },
  },
  {
    path: "msp/customer/domain/reporting/scheduled",
    redirect: {
      name: "domain-reports-scheduled",
    },
  },
  {
    path: "msp/customer/domain/reporting/on-demand",
    redirect: {
      name: "domain-reports-on-demand",
    },
  },
  {
    path: "msp/customer/domain/reporting/archive",
    redirect: {
      name: "domain-reports-archive",
    },
  },
  {
    path: "msp/customer/domain/settings",
    redirect: {
      name: "domain-settings-administrators",
    },
  },
  {
    path: "msp/customer/domain/settings/administrators",
    redirect: {
      name: "domain-settings-administrators",
    },
  },
  {
    path: "msp/customer/domain/user/filtering",
    redirect: {
      name: "user-block-allow-list",
    },
  },
  {
    path: "msp/customer/domain/user/filtering/allow_block_list",
    redirect: {
      name: "user-block-allow-list",
    },
  },
  {
    path: "msp/customer/domain/user/filtering/allow_block_list/emails",
    redirect: {
      name: "user-block-allow-list-emails",
    },
  },
  {
    path: "msp/customer/domain/user/filtering/allow_block_list/domains",
    redirect: {
      name: "user-block-allow-list-domains",
    },
  },
  {
    path: "msp/customer/domain/user/filtering/geoblocking",
    redirect: {
      name: "user-geoblocking",
    },
  },
  {
    path: "msp/customer/domain/user/filtering/geoblocking/rules",
    redirect: {
      name: "user-geoblocking-rule",
    },
  },
  {
    path: "msp/customer/domain/user/filtering/geoblocking/exemptions",
    redirect: {
      name: "user-geoblocking-exemptions",
    },
  },
  {
    path: "msp/customer/domain/user/filtering/link_lock",
    redirect: {
      name: "user-link-lock",
    },
  },
  {
    path: "msp/customer/domain/user/filtering/link_lock/exemptions",
    redirect: {
      name: "user-link-lock-exemptions",
    },
  },
  {
    path: "msp/customer/domain/user/history",
    redirect: {
      name: "user-history",
    },
  },
  {
    path: "msp/customer/domain/user/history/message_overview",
    redirect: {
      name: "user-history",
    },
  },
  {
    path: "msp/customer/domain/user/history/message_overview/:id",
    redirect: {
      name: "user-history-message-overview",
    },
  },
  {
    path: "msp/customer/domain/user",
    redirect: {
      name: "user-quarantine",
    },
  },
  {
    path: "msp/customer/domain/user/quarantine",
    redirect: {
      name: "user-quarantine",
    },
  },
  {
    path: "msp/customer/domain/user/quarantine/message_overview",
    redirect: {
      name: "user-quarantine",
    },
  },
  {
    path: "msp/customer/domain/user/quarantine/message_overview/:id",
    redirect: {
      name: "user-quarantine-message-overview",
    },
  },
];

export default systemRoutes;
