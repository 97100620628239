<template>
  <div v-if="!loading" @click="dropDownClick">
    <cl-sidebar-dropdown :isClosed="isClosed" :testId="dataTestId">
      <template v-slot:title>
        <div class="tw-flex tw-items-center tw-gap-4">
          <div
            class="tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-p-1"
          >
            <component :is="icon" />
          </div>
          <div v-if="!isClosed">
            <cl-tooltip
              v-if="selected.name.length > truncateMaxNumber"
              class="tw-text-primary-400"
              :text="selected.name"
              :id="selected.name"
            >
              <div v-truncate class="tw-text-white">
                {{ selected ? selected.name : "Menu" }}
              </div></cl-tooltip
            >
            <span v-else>{{ selected ? selected.name : "Menu" }}</span>
          </div>
        </div>
      </template>
      <template v-slot:menu>
        <ul class="tw-max-h-[40dvh] tw-w-full tw-overflow-y-auto">
          <li
            v-for="option in availableOptions"
            :key="option.name"
            :data-test-id="`${dataTestId}-${option.name}`"
            class="tw-cursor-pointer tw-p-4 hover:tw-bg-aside-300"
            @click="setSelected(option)"
          >
            {{ option.name }}
          </li>
          <div v-if="fetching" role="status" class="tw-animate-pulse">
            <div
              class="tw-bg-gray-20 dark:tw-bg-aside-20 tw-h-10 tw-w-full tw-bg-dorian-gray-300 dark:tw-bg-aside-300"
            ></div>
          </div>
        </ul>
      </template>
    </cl-sidebar-dropdown>
  </div>
  <div
    v-else
    role="status"
    class="tw-h-12 tw-max-w-[305px] tw-animate-pulse tw-rounded tw-bg-aside-400"
    :class="[isClosed ? 'tw-px-0' : 'tw-mx-2']"
  ></div>
</template>
<script setup>
import { TRUNCATE } from "@/constants";
import { ref, watch, computed } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  isClosed: Boolean,
  options: Array,
  initialSelected: String,
  dataTestId: [String, Number],
  loading: Boolean,
  icon: {
    type: String,
    default: "cl-icon-tabler-building-skyscraper",
  },
});
const store = useStore();
const selected = ref(null);
const availableOptions = ref(null);
const truncateMaxNumber = TRUNCATE.max;

const emit = defineEmits(["selected", "clicked"]);

const fetching = computed(() => {
  return (
    store.getters["msps/fetchingNavList"] || store.getters["domains/fetching"]
  );
});

watch(
  () => props.options,
  () => {
    availableOptions.value = props.options.filter(
      (option) => option.id !== selected.value.id
    );
  }
);
selected.value = props.initialSelected
  ? props.options.filter((item) => item.id === props.initialSelected)
  : props.options[0];
availableOptions.value = props.options.filter(
  (option) => option.id !== selected.value.id
);

const dropDownClick = () => {
  emit("clicked");
};
const setSelected = (selectedOption) => {
  emit("selected", selectedOption);
};
</script>
