import { i18n } from "@/i18n";

export default {
  install(app) {
    app.config.globalProperties.$secondaryNav = {
      toolsNavItems: {
        customer: function () {
          return [
            {
              title: i18n.global.t("Test Categorization"),
              url: `/customer/settings/tools`,
              disabled: false,
            },
            {
              title: i18n.global.t("Recategorize Domain"),
              url: `/customer/settings/tools/recategorize_domain`,
            },
          ];
        },
        geoBlockingMsp: function () {
          return [
            {
              title: i18n.global.t("Rules"),
              url: `/msp/filtering/geoblocking/rules`,
              disabled: false,
            },
            {
              title: i18n.global.t("Exemptions"),
              url: `/msp/filtering/geoblocking/exemptions`,
            },
          ];
        },
        geoBlockingCustomer: function () {
          return [
            {
              title: i18n.global.t("Rules"),
              url: `/customer/filtering/geoblocking/rules`,
              disabled: false,
            },
            {
              title: i18n.global.t("Exemptions"),
              url: `/customer/filtering/geoblocking/exemptions`,
            },
          ];
        },
        geoBlockingDomain: function () {
          return [
            {
              title: i18n.global.t("Rules"),
              url: `/domain/filtering/geoblocking/rules`,
              disabled: false,
            },
            {
              title: i18n.global.t("Exemptions"),
              url: `/domain/filtering/geoblocking/exemptions`,
            },
          ];
        },
        geoBlockingUser: function () {
          return [
            {
              title: i18n.global.t("Rules"),
              url: `/user/filtering/geoblocking/rules`,
              disabled: false,
            },
            {
              title: i18n.global.t("Exemptions"),
              url: `/user/filtering/geoblocking/exemptions`,
            },
          ];
        },
        linkLockMsp: function () {
          return [
            {
              title: i18n.global.t("Policy Configuration"),
              url: `/msp/filtering/link_lock/policy_configuration`,
            },

            {
              title: i18n.global.t("Exemptions"),
              url: `/msp/filtering/link_lock/exemptions`,
            },
          ];
        },
        linkLockCustomer: function () {
          return [
            {
              title: i18n.global.t("Policy Configuration"),
              url: `/customer/filtering/link_lock/policy_configuration`,
            },

            {
              title: i18n.global.t("Exemptions"),
              url: `/customer/filtering/link_lock/exemptions`,
            },
          ];
        },
        linkLockUser: function () {
          return [
            {
              title: i18n.global.t("Policy Configuration"),
              url: `/user/filtering/link_lock/policy_configuration`,
            },

            {
              title: i18n.global.t("Exemptions"),
              url: `/user/filtering/link_lock/exemptions`,
            },
          ];
        },
        linkLockDomain: function () {
          return [
            {
              title: i18n.global.t("Policy Configuration"),
              url: `/domain/filtering/link_lock/policy_configuration`,
            },

            {
              title: i18n.global.t("Exemptions"),
              url: `/domain/filtering/link_lock/exemptions`,
            },
          ];
        },
        system: function () {
          return [
            {
              title: i18n.global.t("Test Categorization"),
              url: `/system/global_settings/tools`,
              disabled: false,
            },
            {
              title: i18n.global.t("Recategorize Domain"),
              url: `/system/global_settings/tools/recategorize_domain`,
            },
            {
              title: i18n.global.t("DNS Lookup"),
              url: `/system/global_settings/tools/dns_lookup`,
            },
          ];
        },
        msp: function () {
          return [
            {
              title: i18n.global.t("Rules"),
              url: `/msp/settings/geoblocking/rule`,
              disabled: false,
            },
            {
              title: i18n.global.t("Exemptions"),
              url: `/msp/settings/geoblocking/exemptions`,
            },
          ];
        },
      },

      customerDefaultPolicyNavItems: function () {
        const navItems = [
          {
            title: i18n.global.t("Policy Details"),
            url: `/customer/settings/default_policy`,
          },
          {
            title: i18n.global.t("Allowed & Blocked Domains"),
            url: `/customer/settings/default_policy/domains`,
          },
          {
            title: i18n.global.t("Notifications"),
            url: `/customer/settings/default_policy/notifications`,
          },
        ];

        return navItems;
      },

      customerPolicyNavItems: function (id, showDevices = true) {
        const navItems = [
          {
            title: i18n.global.t("Policy Details"),
            url: `/customer/filtering/policies/${id}`,
          },
          {
            title: i18n.global.t("Allowed & Blocked Domains"),
            url: `/customer/filtering/policies/${id}/domains`,
          },
          {
            title: i18n.global.t("Notifications"),
            url: `/customer/filtering/policies/${id}/notifications`,
          },
          {
            title: i18n.global.t("Locations"),
            url: `/customer/filtering/policies/${id}/locations`,
          },
        ];

        if (showDevices)
          navItems.push({
            title: i18n.global.t("Devices"),
            url: `/customer/filtering/policies/${id}/devices`,
          });

        return navItems;
      },
      mspQuarantineMessageOverview: function (id) {
        const navItems = [
          {
            title: i18n.global.t("Details"),
            url: `/msp/quarantine/message_overview/${id}/details`,
            disabled: false,
          },
          {
            title: i18n.global.t("Message View"),
            url: `/msp/quarantine/message_overview/${id}/message`,
          },
          {
            title: i18n.global.t("View Source"),
            url: `/msp/quarantine/message_overview/${id}/source`,
          },
        ];
        return navItems;
      },
      customerQuarantineMessageOverview: function (id) {
        const navItems = [
          {
            title: i18n.global.t("Details"),
            url: `/customer/quarantine/message_overview/${id}/details`,
            disabled: false,
          },
          {
            title: i18n.global.t("Message View"),
            url: `/customer/quarantine/message_overview/${id}/message`,
          },
          {
            title: i18n.global.t("View Source"),
            url: `/customer/quarantine/message_overview/${id}/source`,
          },
        ];
        return navItems;
      },
      domainQuarantineMessageOverview: function (id) {
        const navItems = [
          {
            title: i18n.global.t("Details"),
            url: `/domain/quarantine/message_overview/${id}/details`,
            disabled: false,
          },
          {
            title: i18n.global.t("Message View"),
            url: `/domain/quarantine/message_overview/${id}/message`,
          },
          {
            title: i18n.global.t("View Source"),
            url: `/domain/quarantine/message_overview/${id}/source`,
          },
        ];
        return navItems;
      },
      mspHistoryMessageOverview: function (id, quarantined = false) {
        let navItems = [
          {
            title: i18n.global.t("Details"),
            url: `/msp/history/message_overview/${id}/details`,
            disabled: false,
          },
        ];
        if (quarantined) {
          navItems.push({
            title: i18n.global.t("Message View"),
            url: `/msp/history/message_overview/${id}/message`,
            disabled: false,
          });
          navItems.push({
            title: i18n.global.t("View Source"),
            url: `/msp/history/message_overview/${id}/source`,
            disabled: false,
          });
        }
        return navItems;
      },
      customerHistoryMessageOverview: function (id, quarantined = false) {
        let navItems = [
          {
            title: i18n.global.t("Details"),
            url: `/customer/history/message_overview/${id}/details`,
            disabled: false,
          },
        ];
        if (quarantined) {
          navItems.push({
            title: i18n.global.t("Message View"),
            url: `/customer/history/message_overview/${id}/message`,
            disabled: false,
          });
          navItems.push({
            title: i18n.global.t("View Source"),
            url: `/customer/history/message_overview/${id}/source`,
            disabled: false,
          });
        }
        return navItems;
      },
      domainHistoryMessageOverview: function (id, quarantined = false) {
        let navItems = [
          {
            title: i18n.global.t("Details"),
            url: `/domain/history/message_overview/${id}/details`,
            disabled: false,
          },
        ];
        if (quarantined) {
          navItems.push({
            title: i18n.global.t("Message View"),
            url: `/domain/history/message_overview/${id}/message`,
            disabled: false,
          });
          navItems.push({
            title: i18n.global.t("View Source"),
            url: `/domain/history/message_overview/${id}/source`,
            disabled: false,
          });
        }
        return navItems;
      },
      userHistoryMessageOverview: function (id, quarantined = false) {
        let navItems = [
          {
            title: i18n.global.t("Details"),
            url: `/user/history/message_overview/${id}/details`,
            disabled: false,
          },
        ];
        if (quarantined) {
          navItems.push({
            title: i18n.global.t("Message View"),
            url: `/user/history/message_overview/${id}/message`,
            disabled: false,
          });
          navItems.push({
            title: i18n.global.t("View Source"),
            url: `/user/history/message_overview/${id}/source`,
            disabled: false,
          });
        }
        return navItems;
      },
      userQuarantineMessageOverview: function (id) {
        const navItems = [
          {
            title: i18n.global.t("Details"),
            url: `/user/quarantine/message_overview/${id}/details`,
            disabled: false,
          },
          {
            title: i18n.global.t("Message View"),
            url: `/user/quarantine/message_overview/${id}/message`,
          },
          {
            title: i18n.global.t("View Source"),
            url: `/user/quarantine/message_overview/${id}/source`,
          },
        ];
        return navItems;
      },
      domainsNavItems: function () {
        const navItems = [
          {
            title: i18n.global.t("Overview"),
            url: `/domain/overview`,
          },
          {
            title: i18n.global.t("Domain Configuration"),
            url: `/domain/domain-configuration`,
          },
          {
            title: i18n.global.t("Anti-Spoofing"),
            url: `/domain/anti-spoofing`,
          },
        ];
        return navItems;
      },

      mspAttachmentsFiltering: function () {
        return [
          {
            title: i18n.global.t("Extension Filters"),
            url: "/msp/filtering/attachments/extension_filters",
          },
          {
            title: i18n.global.t("File Name Filters"),
            url: "/msp/filtering/attachments/file_name_filters",
          },
          {
            title: i18n.global.t("File Type Filters"),
            url: "/msp/filtering/attachments/file_type_filters",
          },
          {
            title: i18n.global.t("Mime Type Filters"),
            url: "/msp/filtering/attachments/mime_type_filters",
          },
        ];
      },

      customerAttachmentsFiltering: function () {
        return [
          {
            title: i18n.global.t("Extension Filters"),
            url: "/customer/filtering/attachments/extension_filters",
          },
          {
            title: i18n.global.t("File Name Filters"),
            url: "/customer/filtering/attachments/file_name_filters",
          },
          {
            title: i18n.global.t("File Type Filters"),
            url: "/customer/filtering/attachments/file_type_filters",
          },
          {
            title: i18n.global.t("Mime Type Filters"),
            url: "/customer/filtering/attachments/mime_type_filters",
          },
        ];
      },

      domainAttachmentsFiltering: function () {
        return [
          {
            title: i18n.global.t("Extension Filters"),
            url: "/domain/filtering/attachments/extension_filters",
          },
          {
            title: i18n.global.t("File Name Filters"),
            url: "/domain/filtering/attachments/file_name_filters",
          },
          {
            title: i18n.global.t("File Type Filters"),
            url: "/domain/filtering/attachments/file_type_filters",
          },
          {
            title: i18n.global.t("Mime Type Filters"),
            url: "/domain/filtering/attachments/mime_type_filters",
          },
        ];
      },

      userAttachmentsFiltering: function () {
        return [
          {
            title: i18n.global.t("Extension Filters"),
            url: "/user/filtering/attachments/extension_filters",
          },
          {
            title: i18n.global.t("File Name Filters"),
            url: "/user/filtering/attachments/file_name_filters",
          },
          {
            title: i18n.global.t("File Type Filters"),
            url: "/user/filtering/attachments/file_type_filters",
          },
          {
            title: i18n.global.t("Mime Type Filters"),
            url: "/user/filtering/attachments/mime_type_filters",
          },
        ];
      },

      customerBlockAllowList: function () {
        return [
          {
            title: i18n.global.t("Emails"),
            url: "/customer/filtering/allow_block_list/emails",
          },
          {
            title: i18n.global.t("Domains"),
            url: "/customer/filtering/allow_block_list/domains",
          },
        ];
      },

      domainBlockAllowList: function () {
        return [
          {
            title: i18n.global.t("Emails"),
            url: "/domain/filtering/allow_block_list/emails",
          },
          {
            title: i18n.global.t("Domains"),
            url: "/domain/filtering/allow_block_list/domains",
          },
        ];
      },

      endUserBlockAllowList: function () {
        return [
          {
            title: i18n.global.t("Emails"),
            url: "/user/filtering/allow_block_list/emails",
          },
          {
            title: i18n.global.t("Domains"),
            url: "/user/filtering/allow_block_list/domains",
          },
        ];
      },

      mspBlockAllowList: function () {
        return [
          {
            title: i18n.global.t("Emails"),
            url: "/msp/filtering/allow_block_list/emails",
          },
          {
            title: i18n.global.t("Domains"),
            url: "/msp/filtering/allow_block_list/domains",
          },
          {
            title: i18n.global.t("IPs"),
            url: "/msp/filtering/allow_block_list/IPs",
          },
        ];
      },

      systemAdminBlockAllowList: function () {
        return [
          {
            title: i18n.global.t("IPs"),
            url: "/system/filtering/allow_block_list/IPs",
          },
        ];
      },
    };
  },
};
