import { HELPDESK } from "@/constants";

export function setSupportLinks(support) {
  const updatedUserLink = [];
  if (support.link !== null) {
    updatedUserLink.push({
      title: "Support",
      link: support.link,
      icon: "cl-icon-tabler-lifebuoy",
    });
  } else {
    updatedUserLink.push({
      title: "Support",
      link: HELPDESK.link,
      icon: "cl-icon-tabler-lifebuoy",
    });
  }
  if (support.email) {
    updatedUserLink.push({
      title: "Contact",
      link: `mailto:${support.email}`,
      icon: "cl-icon-tabler-mail",
    });
  }
  return updatedUserLink;
}

export default {};
