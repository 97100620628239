export const ACCOUNT_TYPE = Object.freeze({
  SYSTEM: 1,
  SALES: 2,
  MSP: 3,
  CUSTOMER: 4,
  DOMAIN: 5,
  USER: 6,
});

export default {};
