import { $http } from "@/plugins/api.js";

const getDefaultState = () => {
  return {
    domainPolicy: null,
  };
};

// initial state
const state = getDefaultState();

export const getters = {
  domainPolicy: (state) => state.domainPolicy,
};

export const actions = {
  clearCache({ commit }) {
    commit("resetState");
  },

  async fetchDomainPolicy({ commit }, { id }) {
    const res = await $http.get(`/restapi/domains/${id}`);

    commit("setDomainPolicy", res.data.policy);
  },

  async updateDomainPolicy({ commit }, { id, value }) {
    const {
      archive_clean_mail,
      banned,
      geoblocking,
      locked,
      policy_name,
      qreport,
      spam,
      virus,
    } = value;

    const payload = {
      archive_clean_mail,
      banned,
      ...(geoblocking && { geoblocking: geoblocking }),
      ...(locked && { locked: locked }),
      policy_name,
      qreport,
      spam,
      virus,
    };

    const res = await $http.put(`/restapi/domains/${id}/policy`, payload);

    commit("setDomainPolicy", res.data);
    return res.data;
  },
};

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setDomainPolicy: (state, data) => (state.domainPolicy = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
