export const useSort = (currentSortedBy, currentSortedDir) => {
  const applySorting = (sortArray) => {
    const result = sortArray.sort((a, b) => {
      const aValue = a[currentSortedBy];
      const bValue = b[currentSortedBy];

      return currentSortedDir === "desc"
        ? bValue > aValue
          ? 1
          : aValue > bValue
            ? -1
            : 0
        : aValue > bValue
          ? 1
          : bValue > aValue
            ? -1
            : 0;
    });
    return result;
  };
  return {
    applySorting,
  };
};

export default { useSort };
