import { $http } from "@/plugins/api.js";

const state = {
  senders: [],
  selectedSender: null,
  serverTotal: 0,
};

const getters = {
  senders: (state) => (state.senders ? state.senders : []),
  selectedSender: (state) => (state.selectedSender ? state.selectedSender : {}),
  serverTotal: (state) => state.serverTotal,
};

const actions = {
  async fetchSenders(
    { commit },
    {
      domainId,
      results = 10,
      page = 1,
      sortBy = "id",
      sortDir = "desc",
      filter = null,
      type = null,
    }
  ) {
    let requestURL = `/restapi/domains/${domainId}/antispoof/senders`;

    if (results) requestURL += `?results=${results}`;
    if (page) requestURL += `&page=${page}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    if (filter) requestURL += `&filter=${filter}`;
    if (type) requestURL += `&type=${type}`;

    const response = await $http.get(requestURL);
    commit("setSenders", response.data);
  },

  async fetchSender({ commit }, { domainId, id }) {
    const response = await $http.get(
      `/restapi/domains/${domainId}/antispoof/senders/${id}`
    );
    commit("setSelectedSender", response.data);
    commit("setServerTotal", response.data.total);
  },

  async updateSender({ commit }, { domainId, id, sender }) {
    const response = await $http.put(
      `/restapi/domains/${domainId}/antispoof/senders/${id}`,
      {
        domain: domainId,
        id: id,
        ip: sender.ip,
        hostname: sender.hostname,
        comment: sender.comment,
        netmask: sender.netmask,
      }
    );
    commit("setSelectedSender", response.data);
  },

  async createSender({ commit }, { domainId, formData }) {
    const response = await $http.post(
      `/restapi/domains/${domainId}/antispoof/senders`,
      formData
    );
    commit("addSender", response.data);
  },

  async batchDeleteSenders({ commit }, { domainId, senders }) {
    const entries = [];

    for (const sender of senders) {
      const key = `${sender.id}`;
      const value = {
        method: "delete",
        path: `domains/${domainId}/antispoof/senders/${sender.id}`,
      };

      entries.push([key, value]);
    }

    const res = await $http.post("/restapi/batch", Object.fromEntries(entries));
    const ids = Object.keys(res.data).map((item) => item);
    commit("deleteSenders", ids);
  },
  async createSettings(_context, { enabled, use_spf, domainId }) {
    const res = await $http.post(
      `/restapi/domains/${domainId}/antispoof/settings`,
      {
        enabled,
        use_spf,
      }
    );
    return res;
  },
  async updateSettings(_context, { enabled, use_spf, domainId }) {
    const res = await $http.put(
      `/restapi/domains/${domainId}/antispoof/settings`,
      {
        enabled,
        use_spf,
      }
    );
    return res;
  },
  clearList({ commit }) {
    commit("setSenders", []);
  },
};

const mutations = {
  setSenders: (state, data) => (state.senders = data.data),
  setSelectedSender: (state, data) => (state.selectedSender = data),
  addSender: (state, data) => {
    state.senders.push(data);
    state.serverTotal = state.serverTotal + 1;
  },
  updateSender: (state, data) => (state.senders = data),
  deleteSenders(state, ids) {
    for (const id of ids) {
      state.senders = state.senders.filter(
        (sender) => Number(sender.id) !== Number(id)
      );
      state.serverTotal = state.serverTotal - 1;
    }
  },
  setServerTotal: (state, payload) => (state.serverTotal = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
