import { $http } from "@/plugins/api.js";

const getDefaultState = () => {
  return {
    ips: [],
    serverTotal: 0,
  };
};

// initial state
const state = getDefaultState();

export const getters = {
  ips: (state) => state.ips,
  serverTotal: (state) => state.serverTotal,
};

export const actions = {
  clearIPsCache({ commit }) {
    commit("resetState");
  },
  async fetchBlockAllowIPs(
    { commit },
    {
      tier,
      id,
      results = 10,
      page = 1,
      sortBy = "id",
      sortDir = "desc",
      filter = null,
    }
  ) {
    let baseURL =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/ip/allowblock/`
        : `/restapi/ip/allowblock/`;

    if (results) baseURL += `?results=${results}`;
    if (page) baseURL += `&page=${page}`;
    if (sortBy) baseURL += `&sortkey=${sortBy}`;
    if (sortDir) baseURL += `&sortdir=${sortDir}`;
    if (filter) baseURL += `&filter=${filter}`;
    const result = await $http.get(baseURL);

    const ips = result.data.data;
    const total = result.data.total;

    commit("setIPs", ips);
    commit("setServerTotal", total);
  },
  async batchDeleteIPs({ commit }, { ips, tier, id }) {
    const reqAllowedEntries = [];
    const reqBlockedEntries = [];

    for (const ip of ips) {
      if (ip.section === "allowip") {
        const key = `${ip.id}`;
        const value = {
          method: "delete",
          path:
            tier !== null && id !== null
              ? `${tier}/${id}/ip/allow/${ip.id}`
              : `ip/allow/${ip.id}`,
        };

        reqBlockedEntries.push([key, value]);
      } else {
        const key = `${ip.id}`;
        const value = {
          method: "delete",
          path:
            tier !== null && id !== null
              ? `${tier}/${id}/ip/block/${ip.id}`
              : `ip/block/${ip.id}`,
        };

        reqAllowedEntries.push([key, value]);
      }
    }

    if (reqAllowedEntries.length > 0) {
      const res = await $http.post(
        "/restapi/batch",
        Object.fromEntries(reqAllowedEntries)
      );
      const ids = Object.keys(res.data).map((item) => +item);

      commit("deleteIPs", ids);
    }

    if (reqBlockedEntries.length > 0) {
      const res = await $http.post(
        "/restapi/batch",
        Object.fromEntries(reqBlockedEntries)
      );
      const ids = Object.keys(res.data).map((item) => +item);

      commit("deleteIPs", ids);
    }
  },
  async allowIP({ commit }, { entry, tier, id }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/ip/allow`
        : `/restapi/ip/allow`;
    const res = await $http.post(url, entry);

    commit("addIP", res.data);
    return res;
  },
  async updateAllowedIP(_context, { tier, id, formData, ipId }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/ip/allow/${ipId}`
        : `/restapi/ip/allow/${ipId}`;
    return await $http.put(url, formData);
  },
  async blockIP({ commit }, { entry, tier, id }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/ip/block`
        : `/restapi/ip/block`;
    const res = await $http.post(url, entry);

    commit("addIP", res.data);
    return res;
  },
  async updateBlockedIP(_context, { tier, id, formData, ipId }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/ip/block/${ipId}`
        : `/restapi/ip/block/${ipId}`;
    return await $http.put(url, formData);
  },
};

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setIPs: (state, data) => (state.ips = data),
  setServerTotal: (state, payload) => (state.serverTotal = payload),
  deleteIPs(state, ids) {
    for (const id of ids) {
      state.ips = state.ips.filter((ip) => Number(ip.id) !== Number(id));
      state.serverTotal = state.serverTotal - 1;
    }
  },
  addIP: (state, data) => {
    state.ips.push(data);
    state.serverTotal = state.serverTotal + 1;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
