import { createStore } from "vuex";
import VuexPersister from "vuex-persister";

import modules from "@/store/modules";

const vuexPersister = new VuexPersister({});

export const store = createStore({
  modules,
  plugins: [vuexPersister.persist],
  strict: false,
});

export default {};
