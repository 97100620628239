const passwordRoutes = [
  {
    path: "",
    redirect: {
      name: "auth-sign-in",
    },
  },
  {
    path: "sign-in",
    component: () => import("@/views/Auth/SignIn/index.vue"),
    name: "auth-sign-in",
  },
  {
    path: "password/reset",
    name: "auth-password-reset",
    component: () => import("@/views/Auth/Password/Reset/index.vue"),
  },
  {
    path: "password/set",
    name: "auth-password-set",
    component: () => import("@/views/Auth/Password/Set/index.vue"),
  },
];

export default passwordRoutes;
