import { $http } from "@/plugins/api.js";

const getDefaultState = () => {
  return {
    reports: [],
    selectedReport: null,
    serverTotal: 0,
  };
};

// initial state
const state = getDefaultState();

export const getters = {
  reports: (state) => state.reports,
  selectedReport: (state) => state.selectedReport,
  serverTotal: (state) => state.serverTotal,
};

export const actions = {
  clearCache({ commit }) {
    commit("resetState");
  },
  async fetchReports(
    { commit },
    {
      tier,
      id,
      results = 10,
      page = 1,
      sortBy = "created_at",
      sortDir = "desc",
      filter = null,
    }
  ) {
    let requestURL =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/reports/one-off`
        : `/restapi/reports/one-off`;

    if (results) requestURL += `?results=${results}`;
    if (page) requestURL += `&page=${page}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    if (filter) requestURL += `&filter=${filter}`;

    const res = await $http.get(requestURL);

    commit("setReports", res.data.data);
    commit("setServerTotal", res.data.total);
  },

  async fetchReport({ commit }, { tier, id, reportId }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/reports/one-off/${reportId}`
        : `/restapi/reports/one-off/${reportId}`;
    const res = await $http.get(url, {
      headers: {
        Accept: "text/html",
      },
    });

    commit("setSelectedReport", res.data);
  },

  async createReport(_context, { tier, id, formData }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/reports/one-off`
        : `/restapi/reports/one-off`;
    const res = await $http.post(url, formData);

    return res.data;
  },

  async archiveReport({ commit }, { tier, id, reportId }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/reports/one-off/${reportId}/archive`
        : `/restapi/reports/one-off/${reportId}/archive`;

    await $http.put(url);

    commit("setSelectedReport", null);
  },

  async batchDeleteReports({ commit }, { tier, id, reports }) {
    const entries = [];

    for (const report of reports) {
      const key = `${report.id}`;
      const value = {
        method: "delete",
        path:
          tier !== null && id !== null
            ? `${tier}/${id}/reports/one-off/${report.id}`
            : `/reports/one-off/${report.id}`,
      };

      entries.push([key, value]);
    }

    const res = await $http.post("/restapi/batch", Object.fromEntries(entries));
    const ids = Object.keys(res.data).map((item) => item);

    commit("deleteReports", ids);
  },
};

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setReports: (state, data) => (state.reports = data),
  setSelectedReport: (state, data) => (state.selectedReport = data),
  addReport: (state, data) => {
    state.reports.push(data);
    state.serverTotal = state.serverTotal + 1;
  },
  deleteReports(state, ids) {
    for (const id of ids) {
      state.reports = state.reports.filter(
        (report) => report.id !== Number(id)
      );
      state.serverTotal = state.serverTotal - 1;
    }
  },
  setServerTotal: (state, payload) => (state.serverTotal = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
