const state = {
  showModal: false,
  data: null,
};

const actions = {
  openModal: ({ state }) => {
    state.showModal = true;
  },
  closeModal: ({ state }) => {
    state.showModal = false;
  },
  setModalData: ({ state }, data) => {
    state.data = data;
  },
};

const getters = {
  showModal: (state) => state.showModal,
  modalData: (state) => state.data,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
};
