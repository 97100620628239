import { $http } from "@/plugins/api.js";

const state = {
  accounts: [],
  selectedUserAccount: null,
  serverTotal: 0,
};

const getters = {
  userAccounts: (state) => (state.accounts ? state.accounts : []),
  selectedUserAccount: (state) => state.selectedUserAccount,
  serverTotal: (state) => state.serverTotal,
};

const actions = {
  async fetchUsers(
    { commit },
    {
      tier,
      id,
      results = 10,
      page = 1,
      sortBy = "id",
      sortDir = "desc",
      filter = null,
    }
  ) {
    let requestURL =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/users`
        : `/restapi/users`;

    if (results) requestURL += `?results=${results}`;
    if (page) requestURL += `&page=${page}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    if (filter) requestURL += `&filter=${filter}`;

    const res = await $http.get(requestURL);

    commit("setAccounts", res.data);
    commit("setServerTotal", res.data);
  },

  async fetchCustomerAccounts({ commit }) {
    const response = await $http.get(`/restapi/domain-groups/`);
    commit("setAccounts", response.data);
  },

  async fetchCustomerAccount({ commit, state }, { id }) {
    const response = await $http.get(`/restapi/users/${id}`);
    const index = state.accounts.findIndex(
      (account) => account.id === response.data.id
    );
    if (index === -1) {
      commit("addAccount", response.data);
    } else {
      commit("updateAccount", { idx: index, account: response.data });
    }
    commit("setselectedUserAccount", response.data);
  },

  async createCustomerAccount({ commit }) {
    const cus = {
      name: "Example 2",
      timezone: "America/Chicago",
      description: "",
      license: "the license",
    };
    const response = await $http.post("/restapi/domain-groups/", cus);
    commit("addAccount", response.data);
    return response.data;
  },

  async updateCustomerAccount({ commit }, { id, customer }) {
    const response = await $http.post(`/restapi/users/${id}`, customer);
    commit("updateAccount", response.data);
    commit("setselectedUserAccount", response.data);
  },

  async deleteCustomerAccount({ commit }, user_id) {
    const res = await $http.delete(`/domain-groups/${user_id}`);
    const ids = Object.keys(res.data).map((item) => +item);
    commit("deleteAccount", ids);
  },

  async regenerateUserOTGKey({ dispatch }, { id }) {
    await $http.put(`/restapi/users/${id}/devices/key`);

    await dispatch("fetchCustomerAccount", { id });
  },

  clearList({ commit }) {
    commit("setAccounts", []);
  },
};

const mutations = {
  setAccounts: (state, data) => (state.accounts = data.data),
  setServerTotal: (state, data) => (state.serverTotal = data.total),
  setselectedUserAccount: (state, data) => (state.selectedUserAccount = data),
  addAccount: (state, data) => {
    state.accounts.push(data);
    state.serverTotal = state.serverTotal + 1;
  },
  updateAccount: (state, data) =>
    (state.accounts[`${data.idx}`] = data.account),
  deleteAccount(state, users) {
    for (const user of users) {
      state.accounts = state.accounts.filter((account) => account.id !== user);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
