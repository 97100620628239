const logoutPlugin = {
  install(app) {
    app.mixin({
      created() {
        window.addEventListener("storage", this.handleLogoutEvent);
      },
      unmounted() {
        window.removeEventListener("storage", this.handleLogoutEvent);
      },
      methods: {
        handleLogoutEvent(event) {
          if (event.key === "logoutFlag" && event.newValue === "true") {
            this.$router.push("/login");
          }
        },
      },
    });
  },
};

export default logoutPlugin;
