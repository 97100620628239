const state = {
  lang: "",
  timezone: "",
};

const getters = {
  userPrefLang: (state) => {
    return state.lang;
  },
};

const actions = {
  async setUserPrefLang({ commit }, lang) {
    commit("setUserPrefLang", lang);
  },
  async setUserPrefTimezone({ commit }, timezone) {
    commit("setUserPrefTimezone", timezone);
  },
};

const mutations = {
  async setUserPrefLang(state, lang) {
    state.lang = lang;
  },
  async setUserPrefTimezone(state, timezone) {
    state.timezone = timezone;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
