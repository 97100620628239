<template>
  <section class="tw-mb-4 tw-pt-6">
    <header>
      <div>
        <h2>{{ $t("Two-factor Authentication (2FA)") }}</h2>
        <p>
          {{
            $t(
              "Two-factor authentication is an additional layer of security used to protect your account from unauthorized access. If enabled, a user must provide their username, password and a one-time password when logging in."
            )
          }}
        </p>
      </div>
    </header>

    <form v-if="!twoFactorAuthEnabled" autocomplete="off">
      <div class="tw-grid tw-gap-4 sm:tw-grid-cols-2">
        <div>
          <cl-card>
            <template #text>
              <div class="tw-flex tw-justify-center">
                <img :src="qrCodeImage" />
              </div>
            </template>
          </cl-card>
        </div>
        <div>
          <cl-form-label label-for="otp-secret">
            {{ $t("OTP Secret") }}
          </cl-form-label>
          <cl-form-group>
            <cl-form-input
              readonly
              id="otp-secret"
              name="otp-secret"
              v-model:value="otpSecret"
            />
          </cl-form-group>
          <cl-form-label label-for="user">
            {{ $t("User") }}
          </cl-form-label>
          <cl-form-group>
            <cl-form-input
              readonly
              id="user"
              name="user"
              v-model:value="authUser.email"
            />
          </cl-form-group>
          <cl-form-label label-for="code">
            {{ $t("Code") }}
          </cl-form-label>
          <cl-form-group>
            <cl-form-input
              id="code"
              name="code"
              v-model:value="authCode"
              :placeholder="$t('Enter code from your authentication app')"
              :state="setInputState2(v$.authCode)"
              @on-blur="v$.authCode.$touch"
              :disabled="fetching"
            />
            <span
              class="tw-block tw-pt-1 tw-text-sm tw-text-danger-500"
              v-if="v$.authCode.$error"
              >{{ $t("This field is a required field") }}</span
            >
          </cl-form-group>
        </div>
      </div>

      <div class="tw-flex tw-justify-end">
        <cl-button v-if="fetching" variant="secondary">
          <cl-spinner :size="'small'" />
        </cl-button>
        <cl-button
          v-else
          variant="secondary"
          @on-click="submitEnable2fa"
          data-test-id="submit2fa-button"
        >
          <span>{{ this.$t("Enable") }}</span>
        </cl-button>
      </div>
    </form>
    <form
      v-if="twoFactorAuthEnabled"
      @submit.prevent
      autocomplete="off"
      class="tw-mb-5"
    >
      <cl-form-label label-for="2fa-status">
        {{ $t("Two-factor Authentication Status") }}
      </cl-form-label>
      <cl-form-group>
        <cl-form-input readonly :value="$t('On')" id="2fa-status" />
      </cl-form-group>
      <div class="tw-flex tw-justify-end">
        <cl-button v-if="fetching" variant="secondary">
          <cl-spinner :size="'small'" />
        </cl-button>
        <cl-button
          v-else
          variant="secondary"
          @on-click="submitDisable2fa"
          data-test-id="submit-disable-2fa"
        >
          <span>{{ this.$t("Disable") }}</span>
        </cl-button>
      </div>

      <div v-if="isShowBackupCodes">
        <h2>{{ this.$t("Recovery Codes") }}</h2>
        <p class="tw-mb-1">
          {{
            $t(
              "Store these codes carefully. They are the only way to access this application if the device you registered 2FA on is lost. These codes are one-time use and must be used in sequential order."
            )
          }}
        </p>
        <cl-card>
          <template #text>
            <p v-for="(code, idx) in backupCodes" :key="idx" class="mb-0">
              {{ code }}
            </p>
          </template>
        </cl-card>
        <div class="tw-mb-2 tw-flex tw-justify-end">
          <cl-button
            variant="secondary"
            @on-click="copy2faCodes"
            data-test-id="copy2fa-button"
          >
            <span>{{ this.$t("Copy") }}</span>
          </cl-button>
        </div>
      </div>
      <div
        v-if="isShowBackupCodes"
        class="tw-flex tw-items-center tw-justify-center tw-rounded tw-bg-danger-100 tw-p-2 tw-text-danger-500"
      >
        <p class="tw-mb-0">
          {{
            $t(
              "Save your recovery codes now! For security reasons the codes above will not be visible after closing this screen"
            )
          }}
        </p>
      </div>
    </form>
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import qrcode from "qrcode";
import { authenticator } from "otplib";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { Buffer } from "buffer";

window.Buffer = Buffer;

export default {
  name: "Security",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      authCode: {
        required,
      },
    };
  },
  data() {
    return {
      twoFactorAuthEnabled: false,
      fetching: false,
      qrCodeImage: null,
      otpSecret: authenticator.generateSecret(20),
      authCode: "",
      isShowBackupCodes: false,
    };
  },
  computed: {
    ...mapGetters("authentication", ["authUser", "backupCodes"]),
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("authentication", ["enable2fa", "disable2fa"]),
    generateQRCode() {
      const otpauth = authenticator.keyuri(
        this.authUser.email || "Spam Titan User",
        "SPAM TITAN",
        this.otpSecret
      );

      qrcode.toDataURL(otpauth, (err, imageUrl) => {
        if (err) {
          return;
        }
        this.qrCodeImage = imageUrl;
      });
    },
    async copy2faCodes() {
      await navigator.clipboard.writeText(this.backupCodes);

      this.displayToast({
        title: this.$t("Copied"),
        message: this.$t("Copied to clipboard."),
        duration: 2000,
        variant: "success",
      });
    },
    async submitEnable2fa() {
      const isValid = await this.v$.authCode.$validate();

      if (!isValid) {
        return;
      }

      this.fetching = true;

      try {
        await this.enable2fa({
          secret: this.otpSecret,
          code: this.authCode,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Updated"),
          duration: 2000,
          variant: "success",
        });

        this.twoFactorAuthEnabled = true;
        this.isShowBackupCodes = true;
      } finally {
        this.fetching = false;
      }
    },
    async submitDisable2fa() {
      this.fetching = true;

      try {
        await this.disable2fa();

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Disabled"),
          duration: 2000,
          variant: "success",
        });
        this.twoFactorAuthEnabled = false;

        this.generateQRCode();
      } finally {
        this.fetching = false;
      }
    },
    setInputState2(input) {
      return input.$dirty ? !input.$error : null;
    },
  },
  mounted() {
    this.twoFactorAuthEnabled = this.authUser.twoFactorAuthEnabled;
    if (!this.twoFactorAuthEnabled) {
      this.generateQRCode();
    }
    this.isShowBackupCodes = false;
  },
};
</script>
