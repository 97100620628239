<template>
  <h1>Page not found</h1>
</template>

<script>
export default {
  created() {
    this.$router.push({ name: "auth-sing-in" });
  },
};
</script>
