<template>
  <div class="content-filtering-wrap">
    <router-view> </router-view>
  </div>
</template>

<script>
export default {
  name: "ContentFiltering",
};
</script>
