import { $http } from "@/plugins/api.js";
import store from "@/store";
import dayjs from "dayjs";

const getDefaultState = () => {
  return {
    filtersList: [],
    currentFilter: {},
    filtersAppliedLastOn: null,
    serverTotal: 0,
  };
};

const state = getDefaultState();

export const getters = {
  filtersList: (state) => state.filtersList,
  currentFilter: (state) => state.currentFilter,
  filtersAppliedLastOn: (state) => state.filtersAppliedLastOn || null,
  serverTotal: (state) => state.serverTotal,
};

export const actions = {
  clearCache({ commit }) {
    commit("resetState");
  },
  clearSelectedFiltersCache({ commit }) {
    commit("resetSelectedFiltersState");
  },
  async fetchFiltersList(
    { commit },
    {
      tier,
      id,
      type,
      results = 10,
      page = 1,
      sortBy = "name",
      sortDir = "desc",
      filter = null,
    }
  ) {
    let requestURL =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/attachments?type=${type}`
        : `/restapi/attachments?type=${type}`;

    const offset = results * page - results;

    if (results) requestURL += `&limit=${results}`;
    if (page) requestURL += `&offset=${offset}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    if (filter) requestURL += `&filter=${filter}`;

    const res = await $http.get(requestURL);

    commit("setFiltersList", res.data.data);
    commit("setServerTotal", res.data.total);
  },
  async fetchFilter({ commit }, { tier, id, filterId }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/attachments/${filterId}`
        : `/restapi/attachments/${filterId}`;
    const res = await $http.get(url);

    commit("setCurrentFilter", res.data);
  },
  async createFilter({ commit }, { tier, id, formData }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/attachments/`
        : `/restapi/attachments/`;
    const res = await $http.post(url, formData);

    commit("addFilterToList", res.data);
    commit("setServerTotal", state.serverTotal + 1);
  },
  async applyFilters({ commit }, { tier, id }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/attachments/apply`
        : `/restapi/attachments/apply`;
    await $http.post(url);
    commit("setLastAppliedFiltersTime");
  },
  async updateFilter(_context, { tier, id, filterId, formData }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/attachments/${filterId}`
        : `/restapi/attachments/${filterId}`;

    await $http.put(url, formData);
  },
  async batchDeleteFilters({ commit }, { tier, id, filters }) {
    const entries = [];

    for (const filter of filters) {
      const key = `${filter.id}`;
      const value = {
        method: "delete",
        path:
          tier !== null && id !== null
            ? `${tier}/${id}/attachments/${filter.id}`
            : `/attachments/${filter.id}`,
      };

      entries.push([key, value]);
    }

    const res = await $http.post("/restapi/batch", Object.fromEntries(entries));
    const ids = Object.keys(res.data).map((item) => item);

    commit("deleteFilters", ids);
  },
};

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  resetSelectedFiltersState(state) {
    state.filtersList = [];
    state.currentFilter = {};
  },
  setFiltersList: (state, data) => (state.filtersList = data),
  addFilterToList: (state, data) => state.filtersList.push(data),
  deleteFilters(state, ids) {
    for (const id of ids) {
      state.filtersList = state.filtersList.filter(
        (filter) => Number(filter.id) !== Number(id)
      );
      state.serverTotal = state.serverTotal - 1;
    }
  },
  setCurrentFilter: (state, data) => (state.currentFilter = data),
  setLastAppliedFiltersTime(state) {
    state.filtersAppliedLastOn = dayjs();
    store.commit("customers/setFilterTime", state.filtersAppliedLastOn); // need to use other module, because when page is reloaded, this attachmentFilters store updated to default state
  },
  setServerTotal: (state, data) => (state.serverTotal = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
