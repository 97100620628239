// all customer API request should be move here
const state = {
  filterTime: null,
};

const getters = {
  filterTime: (state) => state.filterTime,
};
const mutations = {
  setFilterTime: (state, data) => (state.filterTime = data),
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
