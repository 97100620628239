const getDefaultState = () => {
  return {
    modal: false,
    donains: null,
    spinner: false,
    fileUpload: {
      name: "",
      files: null,
      value: "",
    },
  };
};

// initial state
const state = getDefaultState();

const actions = {
  clearCache({ commit }) {
    commit("resetState");
  },

  setDialog({ commit }, modal) {
    commit("updateModal", modal);
  },
  setSpinenr({ commit }, spinner) {
    commit("updateSpinner", spinner);
  },
  uploaderFile({ commit }, data) {
    commit("updateFileState", data);
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setDomains: (state, data) => (state.domains = data),
  updateModal: (state, modal) => (state.modal = modal),
  updateSpinner: (state, spinner) => (state.spinner = spinner),
  updateFileState: (state, data) => (state.fileUpload = data),
};

const getters = {
  uploadedFiles(state) {
    return state;
  },
  getDomain(state) {
    return state.domains;
  },
  updatedSpinner(state) {
    return state.spinner;
  },
  modal(state) {
    return state.modal;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
