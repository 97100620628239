export default {
  install(app) {
    app.config.globalProperties.$getAccountShortName = (name) =>
      name
        .split(" ")
        .slice(0, 2)
        .map((i) => i.charAt(0))
        .join("");
  },
};
