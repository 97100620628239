import { ref, watch } from "vue";
import { useDark, usePreferredDark } from "@vueuse/core";
import { useCookies } from "@vueuse/integrations/useCookies";

import { THEME } from "@/constants/theme";

export const useTheme = () => {
  const THEME_KEY = "user-preferred-theme";

  const COOKIE_SETTINGS = {
    path: "/", // Important for SPA
    maxAge: 365 * 86400, // 1 Day = 24 Hrs = 24*60*60 = 86400.
  };

  const cookies = useCookies();
  const isSystemPreferredDark = usePreferredDark();

  const userPreferredTheme = ref(cookies.get(THEME_KEY) || THEME.SYSTEM);

  const isThemeDark = useDark({
    selector: "html",
    attribute: "data-theme",
    valueDark: "dark",
    valueLight: "light",
  });

  const setUserPreferredTheme = (theme) => {
    userPreferredTheme.value = theme;

    cookies.set(THEME_KEY, theme, COOKIE_SETTINGS);
  };

  watch(
    () => userPreferredTheme.value,
    () => {
      if (userPreferredTheme.value === THEME.SYSTEM) {
        isThemeDark.value = isSystemPreferredDark.value;
      }

      if (userPreferredTheme.value === THEME.DARK) {
        isThemeDark.value = true;
      }

      if (userPreferredTheme.value === THEME.LIGHT) {
        isThemeDark.value = false;
      }
    },
    { immediate: true }
  );

  return {
    isThemeDark,
    userPreferredTheme,
    setUserPreferredTheme,
  };
};

export default {};
