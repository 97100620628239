import { $http } from "@/plugins/api.js";

const getDefaultState = () => {
  return {
    selectedExemption: [],
    serverTotal: 0,
  };
};

// initial state
const state = getDefaultState();
const getters = {
  geoBlockingExemptions: (state) => state.exemptions,
  selectedGeoBlockingExemption: (state) => state.selectedExemption,
  serverTotal: (state) => state.serverTotal,
};

const actions = {
  clearCache({ commit }) {
    commit("resetState");
  },

  async getGeoExemptions(
    { commit },
    {
      tier,
      id,
      results = 10,
      page = 1,
      sortBy = "created_at",
      sortDir = "desc",
      filter = null,
    }
  ) {
    let requestURL =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/geo-exemptions`
        : `/restapi/geo-exemptions`;

    if (results) requestURL += `?results=${results}`;
    if (page) requestURL += `&page=${page}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    if (filter) requestURL += `&filter=${filter}`;

    const res = await $http.get(requestURL);

    commit("setGeoBlockingExemptions", res.data.data);
    commit("setServerTotal", res.data.total);
  },

  async batchDeleteExemptions({ commit }, { tier, id, exemptions }) {
    const entries = [];

    for (const exemption of exemptions) {
      const key = `${exemption.id}`;
      const value = {
        method: "delete",
        path:
          tier !== null && id !== null
            ? `${tier}/${id}/geo-exemptions/${exemption.id}`
            : `/geo-exemptions/${exemption.id}`,
      };

      entries.push([key, value]);
    }

    const res = await $http.post("/restapi/batch", Object.fromEntries(entries));
    const ids = Object.keys(res.data).map((item) => item);

    commit("deleteExemptions", ids);
  },

  async getGeoBlockingExemption({ commit }, { tier, id, exemptionId }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/geo-exemptions/${exemptionId}`
        : `/restapi/geo-exemptions/${exemptionId}`;
    const res = await $http.get(url);

    commit("setSelectedGeoBlockingExemption", res.data);
  },
  async createGeoBlockingExemption({ commit }, { tier, id, exemption }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/geo-exemptions`
        : `/restapi/geo-exemptions`;
    const res = await $http.post(url, exemption);

    commit("addGeoBlockingExemption", res.data);
  },
  async updateGeoBlockingExemption(
    { commit },
    { tier, id, exemptionId, formData }
  ) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/geo-exemptions/${exemptionId}`
        : `/restapi/geo-exemptions/${exemptionId}`;
    const res = await $http.put(url, formData);

    commit("updateGeoBlockingExemption", res.data);
  },

  clearList({ commit }) {
    commit("setGeoBlockingExemptions", []);
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setGeoBlockingExemptions: (state, data) => (state.exemptions = data),
  deleteExemptions(state, ids) {
    for (const id of ids) {
      state.exemptions = state.exemptions.filter(
        (exemption) => exemption.id !== Number(id)
      );

      state.serverTotal = state.serverTotal - 1;
    }
  },
  updateGeoBlockingExemption: (state, data) =>
    (state.exemptions[`${data.idx}`] = data.exemptions),
  addGeoBlockingExemption: (state, data) => {
    state.exemptions.unshift(data);
    state.serverTotal = state.serverTotal + 1;
  },
  setSelectedGeoBlockingExemption: (state, data) =>
    (state.selectedExemption = data),
  setServerTotal: (state, payload) => (state.serverTotal = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
