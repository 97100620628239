const truncate = {
  mounted(el, binding) {
    const text = el.textContent;
    const maxLength = +binding.value || 22;

    if (text.length > maxLength)
      el.textContent = `${text.substring(0, maxLength)}...`;
  },
};

export default truncate;
