import dayjs from "dayjs";
import { Parser } from "json2csv";

const jsonToCSV = (data) => {
  try {
    const parser = new Parser();
    const csv = parser.parse(data);
    return csv;
  } catch (err) {
    // Log a generic error message to avoid exposing sensitive information
    console.error("An error occurred while converting JSON to CSV.");
    return null;
  } finally {
    console.info("CSV conversion process completed.");
  }
};

export const $downloadCSV = (data, filename) => {
  const csvContent = jsonToCSV(data);

  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);

  a.href = window.URL.createObjectURL(
    new Blob([csvContent], { type: "text/csv" })
  );

  a.setAttribute("download", `${filename}-${dayjs().format("DD-MM-YYYY")}.csv`);

  a.click();

  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
};

export default {
  install(app) {
    app.config.globalProperties.$downloadCSV = $downloadCSV;
  },
};
