import { $http } from "@/plugins/api.js";
import { $getImageUrl } from "@/plugins/imageUrl.js";

const state = {
  logo: "",
  title: "",
  fetchInterfaceError: false,
  interface: {
    nameservers: [],
    system: {},
  },
};

const getters = {
  uiLogo: (state) => state.logo,
  uiTitle: (state) => state.title,
  interface: (state) => state.interface,
};

const actions = {
  async fetchInterface({ commit, dispatch }, { type = null, type_id = null }) {
    dispatch("clearLogoAndTitle");
    let restapiEndpoint;

    type !== null && type_id !== null
      ? (restapiEndpoint =
          `/restapi/${type}/${type_id}/ui/interface` + `?own=true`)
      : (restapiEndpoint = "/restapi/ui/interface");
    const response = await $http.get(restapiEndpoint);

    let logo;
    let title;
    let defaultLightLogo = $getImageUrl("assets/branding/titanhq-logo.svg");
    let defaultTitle = "SpamTitan";

    response.data.logo !== null
      ? (logo = response.data.logo)
      : (logo = defaultLightLogo);
    response.data.title !== null
      ? (title = response.data.title)
      : (title = defaultTitle);

    commit("setInterfaceLogo", logo);
    commit("setInterfaceTitle", title);

    return response.data;
  },

  async updateInterface({ commit }, { body, tier, id }) {
    let url = null;
    if (!tier && !id) {
      url = `/restapi/ui/interface`;
    } else {
      url = `/restapi/${tier}/${id}/ui/interface`;
    }
    const response = await $http.post(url, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    let logo;
    let title;

    let defaultLightLogo = $getImageUrl("assets/branding/titanhq-logo.svg");

    response.data.logo !== null
      ? (logo = response.data.logo)
      : (logo = defaultLightLogo);

    if (response.data.title) title = response.data.title;

    // Set the UI elements:
    commit("setInterfaceLogo", logo);
    commit("setInterfaceTitle", title);

    return response.data;
  },

  async deleteInterfaceUi({ dispatch }, { type, type_id }) {
    await $http.delete(`/restapi/${type}/${type_id}/ui/interface/logo`);
    await $http.delete(`/restapi/${type}/${type_id}/ui/interface/title`);
    dispatch("clearLogoAndTitle");
  },

  async clearLogoAndTitle({ commit }) {
    commit("deleteInterfaceLogo");
    commit("deleteInterfaceTitle");
  },
};

const mutations = {
  deleteInterfaceLogo: (state) => (state.logo = null),
  deleteInterfaceTitle: (state) => (state.title = ""),
  setInterfaceLogo: (state, data) => (state.logo = data),
  setInterfaceTitle: (state, data) => (state.title = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
