import { $http } from "@/plugins/api.js";

const getDefaultState = () => {
  return {
    emails: [],
    serverTotal: 0,
  };
};

// initial state
const state = getDefaultState();

export const getters = {
  emails: (state) => state.emails,
  serverTotal: (state) => state.serverTotal,
};

export const actions = {
  clearEmailsCache({ commit }) {
    commit("resetState");
  },
  async fetchBlockAllowEmails(
    { commit },
    {
      tier,
      id,
      results = 10,
      page = 1,
      sortBy = "sender",
      sortDir = "desc",
      filter = null,
    }
  ) {
    const params = "type=email";

    let requestURL =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/allow-block-list?${params}`
        : `/restapi/allow-block-list?${params}`;

    if (results) requestURL += `&results=${results}`;
    if (page) requestURL += `&page=${page}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    if (filter) requestURL += `&filter=${filter}`;

    const result = await $http.get(requestURL);

    commit("setEmails", result.data.data);
    commit("setServerTotal", result.data.total);
  },
  async blockEmail({ commit }, { entry, tier, id }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/block-list`
        : `/restapi/block-list`;
    const res = await $http.post(url, entry);

    commit("addEmail", res.data);
    return res;
  },
  async updateBlockedEmail(_context, { tier, id, emailId, formData }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/block-list/${emailId}`
        : `/restapi/block-list/${emailId}`;
    const res = await $http.put(url, formData);

    return res;
  },
  async allowEmail({ commit }, { entry, tier, id }) {
    const tierUrl =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/allow-list`
        : `/restapi/allow-list`;

    const res = await $http.post(tierUrl, entry);

    commit("addEmail", res.data);
    return res;
  },
  async updateAllowedEmail(_context, { tier, id, emailId, formData }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/allow-list/${emailId}`
        : `/restapi/allow-list/${emailId}`;
    const res = await $http.put(url, formData);

    return res;
  },
  async batchDeleteEmails({ commit }, { emails, tier, id }) {
    const reqAllowedEntries = [];
    const reqBlockedEntries = [];

    for (const email of emails) {
      if (email.status.toUpperCase() === "BLOCKED") {
        const key = `${email.id}`;
        const value = {
          method: "delete",
          path:
            tier !== null && id !== null
              ? `${tier}/${id}/block-list/${email.id}`
              : `block-list/${email.id}`,
        };

        reqBlockedEntries.push([key, value]);
      } else {
        const key = `${email.id}`;
        const value = {
          method: "delete",
          path:
            tier !== null && id !== null
              ? `${tier}/${id}/allow-list/${email.id}`
              : `allow-list/${email.id}`,
        };

        reqAllowedEntries.push([key, value]);
      }
    }

    if (reqAllowedEntries.length > 0) {
      const res = await $http.post(
        "/restapi/batch",
        Object.fromEntries(reqAllowedEntries)
      );
      const ids = Object.keys(res.data).map((item) => +item);

      commit("deleteEmails", ids);
    }

    if (reqBlockedEntries.length > 0) {
      const res = await $http.post(
        "/restapi/batch",
        Object.fromEntries(reqBlockedEntries)
      );
      const ids = Object.keys(res.data).map((item) => +item);

      commit("deleteEmails", ids);
    }
  },
};

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setEmails: (state, data) => (state.emails = data),
  setServerTotal: (state, payload) => (state.serverTotal = payload),
  deleteEmails(state, ids) {
    for (const id of ids) {
      state.emails = state.emails.filter(
        (email) => Number(email.id) !== Number(id)
      );
      state.serverTotal = state.serverTotal - 1;
    }
  },
  addEmail: (state, data) => {
    state.emails.push(data);
    state.serverTotal = state.serverTotal + 1;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
