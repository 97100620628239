import { $http } from "@/plugins/api.js";

const getDefaultState = () => {
  return {
    status: false,
  };
};

// initial state
const state = getDefaultState();
const getters = {
  geoblockingStatus: (state) => state.status,
};

const actions = {
  async getGeoBlockingStatus({ commit }, { tier, id }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/geoblocking`
        : `/restapi/geoblocking`;
    const res = await $http.get(url);

    commit("setGeoblockingStatus", res.data);
  },
  async setGeoBlockingStatus({ commit }, { tier, id, status }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/geoblocking`
        : `/restapi/geoblocking`;
    const res = await $http.post(url, { enabled: status });

    commit("setGeoblockingStatus", res.data);
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setGeoblockingStatus: (state, data) => (state.status = data.enabled),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
