import { $http } from "@/plugins/api.js";

const state = {
  patterns: [],
  pattern: null,
  serverTotal: 0,
};

const getters = {
  patterns: (state) => state.patterns,
  pattern: (state) => state.pattern,
  serverTotal: (state) => state.serverTotal,
};

const actions = {
  clearList({ commit }) {
    commit("setPatterns", []);
  },
  clearPattern({ commit }) {
    commit("setPattern", null);
  },
  async fetchPatterns(
    { commit },
    {
      tier,
      id,
      results = 10,
      page = 1,
      sortBy = "pattern",
      sortDir = "desc",
      filter = null,
    }
  ) {
    let requestURL =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/patterns`
        : `/restapi/patterns`;

    if (results) requestURL += `?results=${results}`;
    if (page) requestURL += `&page=${page}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    if (filter) requestURL += `&filter=${filter}`;

    const res = await $http.get(requestURL);

    commit("setPatterns", res.data);
    commit("setServerTotal", res.data.total);
  },
  async addPattern({ commit }, { tier, id, formData }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/patterns`
        : `/restapi/patterns`;
    const res = await $http.post(url, formData);

    commit("addPattern", res.data);
  },
  async fetchPatternDetails({ commit }, { tier, id, patternId }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/patterns/${patternId}`
        : `/restapi/patterns/${patternId}`;
    const res = await $http.get(url);

    commit("setPattern", res.data);

    return res;
  },
  async savePatternDetails({ commit }, { tier, id, pattern }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/patterns/${pattern.id}`
        : `/restapi/patterns/${pattern.id}`;
    const res = await $http.put(url, pattern);

    commit("setPattern", res.data);
  },
  async batchDeletePatterns(_context, { tier, id, ids }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/patterns`
        : `/restapi/patterns`;

    await $http.post(url, {
      _method: "DELETE",
      ids,
    });
  },
};

const mutations = {
  setPatterns: (state, data) => (state.patterns = data.data),
  addPattern: (state, data) => {
    state.patterns.push(data);
    state.serverTotal = state.serverTotal + 1;
  },
  deletePatterns(state, ids) {
    for (const id of ids) {
      state.patterns = state.patterns.filter(
        (pattern) => pattern.id !== Number(id)
      );
      state.serverTotal = state.serverTotal + 1;
    }
  },
  setPattern: (state, data) => (state.pattern = data),
  setServerTotal: (state, payload) => (state.serverTotal = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
