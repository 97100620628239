import Settings from "@/views/Customer/Settings";

const domainRoutes = [
  {
    path: "",
    name: "selected-domain",
    component: () => import("@/views/Domain/Overview"),
    redirect: {
      name: "domain-overview",
    },
    children: [
      {
        path: "overview",
        name: "domain-overview",
        component: () => import("@/views/Domain/Overview/Tabs/overview"),
      },
      {
        path: "domain-configuration",
        name: "domain-configuration",
        component: () =>
          import("@/views/Domain/Overview/Tabs/domain-configuration"),
      },
      {
        path: "anti-spoofing",
        name: "anti-spoofing",
        component: () => import("@/views/Domain/Overview/Tabs/anti-spoofing"),
      },
    ],
  },
  {
    path: "anti-spoofing/:id",
    name: "anti-spoofing-edit",
    component: () => import("@/views/Domain/Overview/Tabs/anti-spoofing/Edit"),
  },
  {
    path: "filtering",
    redirect: {
      name: "domain-filtering-attachments",
    },
    component: () => import("@/views/Domain/ContentFiltering"),
    children: [
      {
        path: "attachments",
        name: "domain-filtering-attachments",
        component: () => import("@/views/Domain/ContentFiltering/Attachments"),
        redirect: {
          name: "domain-filtering-attachments-extension",
        },
        children: [
          {
            path: "extension_filters",
            name: "domain-filtering-attachments-extension",
            component: () =>
              import(
                "@/views/Domain/ContentFiltering/Attachments/Filters/Extension.vue"
              ),
          },
          {
            path: "file_name_filters",
            name: "domain-filtering-attachments-filename",
            component: () =>
              import(
                "@/views/Domain/ContentFiltering/Attachments/Filters/FileName.vue"
              ),
          },
          {
            path: "file_type_filters",
            name: "domain-filtering-attachments-filetype",
            component: () =>
              import(
                "@/views/Domain/ContentFiltering/Attachments/Filters/FileType.vue"
              ),
          },
          {
            path: "mime_type_filters",
            name: "domain-filtering-attachments-mimetype",
            component: () =>
              import(
                "@/views/Domain/ContentFiltering/Attachments/Filters/MimeType.vue"
              ),
          },
          {
            path: "password_protected_archive",
            name: "domain-filtering-attachments-ppa",
            component: () =>
              import(
                "@/views/Domain/ContentFiltering/Attachments/Filters/PasswordProtectedArchive.vue"
              ),
          },
        ],
      },
      {
        path: "attachments/:id",
        name: "domain-filtering-attachments-edit",
        component: () =>
          import(
            "@/views/Domain/ContentFiltering/Attachments/Filters/Edit/index.vue"
          ),
      },
      {
        path: "pattern_filtering",
        name: "domain-filtering-patterns-list",
        component: () =>
          import("@/views/Domain/ContentFiltering/Patterns/index.vue"),
      },
      {
        path: "pattern_filtering/edit_single_pattern",
        redirect: {
          name: "domain-filtering-patterns-list",
        },
      },
      {
        path: "pattern_filtering/edit_multi_pattern",
        redirect: {
          name: "domain-filtering-patterns-list",
        },
      },
      {
        path: "pattern_filtering/edit_multi_pattern/:id",
        name: "domain-filtering-patterns-edit-multi",
        component: () =>
          import("@/views/Domain/ContentFiltering/Patterns/Edit/Multi.vue"),
      },
      {
        path: "pattern_filtering/edit_single_pattern/:id",
        name: "domain-filtering-patterns-edit-single",
        component: () =>
          import("@/views/Domain/ContentFiltering/Patterns/Edit/Single.vue"),
      },
      {
        path: "geoblocking",
        name: "domain-geoblocking",
        component: () =>
          import("@/views/Domain/ContentFiltering/Geoblocking/index.vue"),
        redirect: {
          name: "domain-geoblocking-rule",
        },
        children: [
          {
            path: "rules",
            name: "domain-geoblocking-rule",
            component: () =>
              import("@/views/Domain/ContentFiltering/Geoblocking/Tabs/Rules"),
          },
          {
            path: "exemptions",
            name: "domain-geoblocking-exemptions",
            component: () =>
              import(
                "@/views/Domain/ContentFiltering/Geoblocking/Tabs/Exemptions"
              ),
          },
        ],
      },
      {
        path: "geoblocking/rules/:iso",
        name: "domain-geoblocking-rule-edit",
        component: () =>
          import(
            "@/views/Domain/ContentFiltering/Geoblocking/Tabs/Rules/Edit/index.vue"
          ),
      },
      {
        path: "geoblocking/exemptions/:id",
        name: "domain-geoblocking-exemptions-edit",
        component: () =>
          import(
            "@/views/Domain/ContentFiltering/Geoblocking/Tabs/Exemptions/Edit"
          ),
      },
      {
        path: "allow_block_list",
        name: "domain-block-allow-list",
        component: () =>
          import("@/views/Domain/ContentFiltering/BlockAllowList"),
        redirect: {
          name: "domain-block-allow-list-emails",
        },
        children: [
          {
            path: "emails",
            name: "domain-block-allow-list-emails",
            component: () =>
              import("@/views/Domain/ContentFiltering/BlockAllowList/Emails"),
          },
          {
            path: "domains",
            name: "domain-block-allow-list-domains",
            component: () =>
              import("@/views/Domain/ContentFiltering/BlockAllowList/Domains"),
          },
        ],
      },
      {
        path: "allow_block_list/emails/:id",
        name: "domain-block-allow-list-emails-edit",
        component: () =>
          import("@/views/Domain/ContentFiltering/BlockAllowList/Emails/Edit"),
      },
      {
        path: "allow_block_list/domains/:id",
        name: "domain-block-allow-list-domains-edit",
        component: () =>
          import("@/views/Domain/ContentFiltering/BlockAllowList/Domains/Edit"),
      },
      {
        path: "link_lock",
        name: "domain-link-lock",
        component: () =>
          import("@/views/Domain/ContentFiltering/LinkLock/index.vue"),
        children: [
          {
            path: "policy_configuration",
            name: "domain-link-lock-policy-configuration",
            component: () =>
              import(
                "@/views/Domain/ContentFiltering/LinkLock/Tabs/PolicyConfiguration/index.vue"
              ),
          },
          {
            path: "exemptions",
            name: "domain-link-lock-exemptions",
            component: () =>
              import(
                "@/views/Domain/ContentFiltering/LinkLock/Tabs/Exemptions/index.vue"
              ),
          },
        ],
      },
      {
        path: "link_lock/exemptions/:id",
        name: "domain-link-lock-exemptions-edit",
        component: () =>
          import(
            "@/views/Domain/ContentFiltering/LinkLock/Tabs/Exemptions/Edit"
          ),
      },
    ],
  },
  {
    path: "quarantine",
    component: () => import("@/views/Domain/Quarantine"),
    name: "domain-quarantine",
  },
  {
    path: "history",
    name: "domain-history",
    component: () => import("@/views/Domain/History/index.vue"),
  },
  {
    path: "history/message_overview",
    name: "domain-history-message",
    redirect: {
      name: "domain-history",
    },
  },
  {
    path: "history/message_overview/:id",
    component: () => import("@/views/Domain/History/MessageOverview/index.vue"),
    name: "domain-history-message-overview",
    redirect: {
      name: "domain-history-message-overview-details",
    },
    children: [
      {
        path: "details",
        name: "domain-history-message-overview-details",
        component: () =>
          import("@/views/Domain/History/MessageOverview/Tabs/Details.vue"),
      },
      {
        path: "message",
        name: "domain-history-message-overview-message",
        component: () =>
          import("@/views/Domain/History/MessageOverview/Tabs/Message.vue"),
      },
      {
        path: "source",
        name: "domain-history-message-overview-source",
        component: () =>
          import("@/views/Domain/History/MessageOverview/Tabs/Source.vue"),
      },
    ],
  },
  {
    path: "quarantine/message_overview",
    name: "domain-quarantine-message",
    redirect: {
      name: "domain-quarantine",
    },
  },
  {
    path: "quarantine/message_overview/:id",
    component: () =>
      import("@/views/Domain/Quarantine/MessageOverview/index.vue"),
    name: "domain-quarantine-message-overview",
    redirect: {
      name: "domain-quarantine-message-overview-details",
    },
    children: [
      {
        path: "details",
        name: "domain-quarantine-message-overview-details",
        component: () =>
          import("@/views/Domain/Quarantine/MessageOverview/Tabs/Details.vue"),
      },
      {
        path: "message",
        name: "domain-quarantine-message-overview-message",
        component: () =>
          import("@/views/Domain/Quarantine/MessageOverview/Tabs/Message.vue"),
      },
      {
        path: "source",
        name: "domain-quarantine-message-overview-source",
        component: () =>
          import("@/views/Domain/Quarantine/MessageOverview/Tabs/Source.vue"),
      },
    ],
  },
  {
    path: "policies",
    name: "domain-policies",
    component: () => import("@/views/Domain/Policies/index.vue"),
    redirect: {
      name: "domain-policies-domain-policy",
    },
    children: [
      {
        path: "domain_policy",
        component: () =>
          import("@/views/Domain/Policies/DomainPolicy/index.vue"),
        name: "domain-policies-domain-policy",
      },
      {
        path: "user_policies",
        name: "domain-policies-user-policies",
        component: () => import("@/views/Domain/Policies/UserPolicy/index.vue"),
      },
      {
        path: "user_policies/:id",
        name: "domain-policies-user-policy-details",
        component: () =>
          import("@/views/Domain/Policies/UserPolicy/Details/index.vue"),
      },
    ],
  },
  {
    path: "reporting",
    name: "domain-reports",
    redirect: {
      name: "domain-reports-scheduled",
    },
    component: () => import("@/views/Domain/Reporting"),
    children: [
      {
        path: "scheduled",
        name: "domain-reports-scheduled",
        component: () =>
          import("@/views/Domain/Reporting/ScheduledReports/List"),
      },
      {
        path: "scheduled/:id",
        name: "domain-reports-scheduled-edit",
        component: () =>
          import("@/views/Domain/Reporting/ScheduledReports/Edit"),
      },
      {
        path: "on-demand",
        name: "domain-reports-on-demand",
        component: () => import("@/views/Domain/Reporting/OnDemand/List"),
      },
      {
        path: "on-demand/:id",
        name: "domain-reports-on-demand-preview",
        component: () => import("@/views/Domain/Reporting/OnDemand/Preview"),
      },
      {
        path: "archive",
        name: "domain-reports-archive",
        component: () => import("@/views/Domain/Reporting/Archive/List"),
      },
      {
        path: "archive/:id",
        name: "domain-reports-archive-preview",
        component: () => import("@/views/Domain/Reporting/Archive/Preview"),
      },
    ],
  },
  {
    path: "settings",
    name: "domain-settings",
    component: Settings,
    redirect: {
      name: "domain-settings-administrators",
    },
    children: [
      {
        path: "administrators",
        name: "domain-settings-administrators",
        component: () =>
          import("@/views/Domain/Settings/Administrators/index.vue"),
      },
      {
        path: "administrators/:id",
        name: "domain-settings-administrators-edit",
        component: () => import("@/views/Common/Administrators/Edit"),
      },
    ],
  },
  {
    path: "user",
    redirect: {
      name: "user-quarantine",
    },
  },
  {
    path: "user/filtering",
    redirect: {
      name: "user-block-allow-list",
    },
  },
  {
    path: "user/filtering/allow_block_list",
    redirect: {
      name: "user-block-allow-list",
    },
  },
  {
    path: "user/filtering/allow_block_list/emails",
    redirect: {
      name: "user-block-allow-list-emails",
    },
  },
  {
    path: "user/filtering/allow_block_list/domains",
    redirect: {
      name: "user-block-allow-list-domains",
    },
  },
  {
    path: "user/filtering/geoblocking",
    redirect: {
      name: "user-geoblocking",
    },
  },
  {
    path: "user/filtering/geoblocking/exemptions",
    redirect: {
      name: "user-geoblocking-exemptions",
    },
  },
  {
    path: "user/filtering/geoblocking/rules",
    redirect: {
      name: "user-geoblocking-rule",
    },
  },
  {
    path: "user/filtering/link_lock",
    redirect: {
      name: "user-link-lock",
    },
  },
  {
    path: "user/filtering/link_lock/exemptions",
    redirect: {
      name: "user-link-lock-exemptions",
    },
  },
  {
    path: "user/history",
    redirect: {
      name: "user-history",
    },
  },
  {
    path: "user/history/message_overview",
    redirect: {
      name: "user-history",
    },
  },
  {
    path: "user/history/message_overview/:id",
    redirect: {
      name: "user-history-message-overview",
    },
  },
  {
    path: "user/quarantine",
    redirect: {
      name: "user-quarantine",
    },
  },
  {
    path: "user/quarantine/message_overview",
    redirect: {
      name: "user-quarantine",
    },
  },
  {
    path: "user/quarantine/message_overview/:id",
    redirect: {
      name: "user-quarantine-message-overview",
    },
  },
];

export default domainRoutes;
