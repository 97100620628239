// Based on PUI-PHISHTITAN:
// ^ Starting of string
//  (?=.{12,}$) Check there is at least 12 characters in the string.
//              As this is lookahead the position of checking will reset to starting again
//  (?=.*[a-z]) Check if there is at least one lowercase in string.
//              As this is lookahead the position of checking will reset to starting again
//  (?=.*[A-Z]) Check if there is at least one uppercase in string.
//              As this is lookahead the position of checking will reset to starting again
//  (?=.*[0-9]) Check if there is at least one digit in string.
//              As this is lookahead the position of checking will reset to starting again
//  (?=.*\W) Check if there is at least one special character in string.
//           As this is lookahead the position of checking will reset to starting again
// .*$ Capture the entire string if all the condition of lookahead is met. This is not required if only validation is needed

export const STRONG_PASSWORD =
  /^(?=.{12,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;

export default {};
