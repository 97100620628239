export const STEPS = Object.freeze({
  first: "FIRST",
  second: "SECOND",
});

export const TRUNCATE = Object.freeze({
  max: 22,
});

export default {};
