import { encode as base64encode } from "base64-arraybuffer";

import { ACCOUNT_TYPE } from "@/constants";
import { $http } from "@/plugins/api.js";
import crypto from "crypto";

async function generateCodeChallenge(codeVerifier) {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await window.crypto.subtle.digest("SHA-256", data);
  const base64Digest = base64encode(digest);
  return base64Digest.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
}

function secureRandomString(length) {
  // Generate cryptographically secure random bytes
  const secureBytes = crypto.randomBytes(length);

  // Map the bytes to alphanumeric characters
  const secureString = secureBytes
    .map((byte) => (byte % 36).toString(36)) // Converting to alphanumeric
    .join("");

  return secureString;
}

const state = {
  selectedAccount: null,
  selectedMsp: null,
  selectedCustomer: null,
  selectedDomain: null,
  selectedUser: null,
  token: null,
  role: null,
  accounts: null,
  authUser: null,
  mspId: null,
  backupCodes: null,
  permissions: null,
};

const getters = {
  selectedAccount: (state) => state.selectedAccount,
  selectedMsp: (state) => state.selectedMsp,
  selectedCustomer: (state) => state.selectedCustomer,
  selectedDomain: (state) => state.selectedDomain,
  selectedUser: (state) => state.selectedUser,
  token: (state) => state.token,
  accounts: (state) => state.accounts,
  role: (state) => state.role,
  authUser: (state) => state.authUser,
  mspId: (state) => state.mspId,
  backupCodes: (state) => state.backupCodes,
  permissions: (state) => state.permissions,
};

const actions = {
  /**
   * Either the user id or the email address must be provided.
   * If the oauth keys have already been created, they are returned without any further changes.
   * @values user: { id: Number, email: String }
   * @returns userObject
   */
  async registerCustomerForOAuth({ commit }, user) {
    const response = await $http.post("/restapi/auth/adminregister/", user);

    commit("registerCustomer", response.data);
    return response.data;
  },
  async setToken({ commit }, token) {
    commit("setToken", token);
  },

  async setCustomer({ commit }, customer) {
    commit("setSelectedCustomer", customer);
  },

  async setDomain({ commit }, domain) {
    commit("setSelectedDomain", domain);
  },

  async setUser({ commit }, selectedUser) {
    commit("setUser", selectedUser);
  },

  async setMspUser({ commit }, selectedMsp) {
    commit("setMspUser", selectedMsp);
  },

  async setMspId({ commit }, mspId) {
    commit("setMspId", mspId);
  },

  async setRole({ commit }, role) {
    commit("setRole", role);
  },

  async enable2fa({ commit }, code) {
    const response = await $http.post("/restapi/auth/two-factor-auth", code);

    commit("set2Fa", response.data.created);
    commit("setBackupCodes", response.data.backupCodes);
  },

  async disable2fa({ commit }) {
    const response = await $http.delete("/restapi/auth/two-factor-auth");

    commit("set2Fa", !response.data.disabled);
    commit("setBackupCodes", []);
  },

  async requestToken({ commit }, user) {
    const code_verifier = secureRandomString(128);
    const state = secureRandomString(40);
    const code_challenge = await generateCodeChallenge(code_verifier);
    const client_id = import.meta.env.VITE_API_CLIENT_ID;
    const code_challenge_method = "S256";

    let params = {
      state,
      code_verifier,
      code_challenge,
      redirect_uri: import.meta.env.VITE_API_URL + "/restapi/auth/callback",
      client_id,
      code_challenge_method,
      response_type: "code",
      email: user["username"],
    };

    if (user["code"]) {
      params = { ...params, code: user["code"] };
    }

    const auth = {
      username: user["username"],
      password: user["password"],
    };

    const response = await $http.get("/restapi/auth/authorize", {
      params: params,
      auth,
    });

    $http.setAuthorisationToken(response.data.access_token);

    commit("setToken", response.data);

    const tokens = response.data.access_token.split(".");
    const payload = atob(tokens[1]);
    const jwtPayload = JSON.parse(payload);
    const twoFactorAuthEnabled = jwtPayload["twofa_enabled"] || false;
    const accounts = jwtPayload["accounts"] || [];
    const permissions = jwtPayload["permissions"] || [];

    commit("setAccounts", accounts);
    commit("setPermissions", permissions);

    commit("setAuthUser", {
      id: jwtPayload["sub"],
      accountname: `${jwtPayload["first_name"]} ${jwtPayload["last_name"]}`,
      email: jwtPayload["email"] || "",
      twoFactorAuthEnabled: twoFactorAuthEnabled,
    });

    if (accounts.length) {
      commit("setSelectedAccount", accounts[0]);

      if (accounts[0]["account_type"] === ACCOUNT_TYPE.MSP) {
        // commit("setMspId", jwtPayload["msp_id"]);
        commit("setMspUser", {
          id: accounts[0]["account_id"],
          name: accounts[0]["account_name"],
          email: jwtPayload["email"] || "",
        });
      }

      if (accounts[0]["account_type"] === ACCOUNT_TYPE.DOMAIN) {
        const domain = {
          id: accounts[0]["account_id"],
          name: accounts[0]["account_name"],
          email: jwtPayload["email"] || "",
        };
        commit("setSelectedDomain", domain);
      }

      if (accounts[0]["account_type"] === ACCOUNT_TYPE.CUSTOMER) {
        const customer = {
          id: accounts[0]["account_id"],
          name: accounts[0]["account_name"],
          email: jwtPayload["email"] || "",
          allCustomers: false,
        };
        commit("setSelectedCustomer", customer);
      }

      if (accounts[0]["account_type"] === ACCOUNT_TYPE.USER) {
        const user = {
          id: accounts[0]["account_id"],
          name: accounts[0]["account_name"],
          email: jwtPayload["email"] || "",
        };
        commit("setUser", user);
      }
    }

    return response;
  },

  async forgotPassword({ _ }, email) {
    const response = await $http.post("/restapi/auth/password/forgot", email);

    return response.data;
  },

  async updatePassword({ _ }, { body }) {
    const response = await $http.put(`/restapi/auth/password`, body);
    return response.data;
  },

  clearToken({ commit }) {
    commit("setToken", null);
  },
};

const mutations = {
  setUser: (state, selectedUser) =>
    (state.selectedUser = {
      accountname: selectedUser.name,
      name: selectedUser.name,
      email: selectedUser.email,
      id: selectedUser.id,
      allCustomers: selectedUser.allCustomers,
    }),
  setToken: (state, token) => (state.token = token),
  setAccounts: (state, accounts) => (state.accounts = accounts),
  setPermissions: (state, permissions) => (state.permissions = permissions),
  setRole: (state, role) => (state.role = role),
  set2Fa: (state, enabled) => (state.authUser.twoFactorAuthEnabled = enabled),
  setBackupCodes: (state, codes) => (state.backupCodes = codes),
  setMspId: (state, mspId) => (state.mspId = mspId),
  setAuthUser: (state, authUser) => (state.authUser = authUser),
  setSelectedCustomer: (state, customer) => (state.selectedCustomer = customer),
  setSelectedDomain: (state, domain) => (state.selectedDomain = domain),
  setMspUser: (state, selectedMsp) => (state.selectedMsp = selectedMsp),
  setSelectedAccount: (state, selectedAccount) =>
    (state.selectedAccount = selectedAccount),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
