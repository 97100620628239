<template>
  <cl-tabs class="tw-mb-5">
    <router-link
      v-for="item in items"
      :key="item.url"
      :to="item.url"
      v-slot="{ href, navigate, isExactActive }"
      :data-test-id="`geoblocking-tab-${item.title.toLowerCase()}`"
    >
      <a
        :href="href"
        :class="{ 'tw-pointer-events-none': item.disabled }"
        @click="navigate"
      >
        <cl-tabs-item :active="isExactActive" :disabled="item.disabled">
          {{ $t(item.title) }}
        </cl-tabs-item>
      </a>
    </router-link>
  </cl-tabs>
</template>
<script>
export default {
  name: "TTabs",
  props: {
    items: {
      type: Object,
      required: true,
    },
  },
};
</script>
