<template>
  <div class="tw-mb-4 tw-bg-secondary-100">
    <div class="tw-grid tw-gap-4 md:tw-grid-cols-4">
      <div class="tw-col-span-3 tw-p-2 tw-text-secondary-800">
        {{ $t("Select") }}
        <b>{{ $t("Apply Filters") }}</b>
        {{
          $t(`after making updates to implement changes (this action is available
        every five minutes).`)
        }}
      </div>
      <div class="tw-flex tw-items-center tw-justify-end tw-p-2">
        <cl-button
          type="submit"
          @on-click="onApplyFilters"
          :disabled="!isApplyActionAvailable"
          variant="secondary"
        >
          <span v-if="!applying">{{ $t("Apply Filters") }}</span>
          <cl-spinner v-else size="small" />
        </cl-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  name: "ApplyFilters",
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    tier: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isApplyActionAvailable: true,
      applying: false,
      totalApplyActionTimeoutInMS: 300000,
    };
  },
  computed: {
    ...mapGetters("attachmentFilters", ["filtersList"]),
    ...mapGetters("customers", ["filterTime"]),
    filter() {
      return localStorage.getItem("filter");
    },
  },
  watch: {
    filterTime() {
      this.isApplyActionAvailable =
        this.filterTime === null ||
        dayjs().diff(this.filterTime, "ms") >= this.totalApplyActionTimeoutInMS;
    },
  },
  methods: {
    ...mapActions("toast", ["displayToast"]),
    ...mapActions("attachmentFilters", [
      "applyFilters",
      "clearSelectedFiltersCache",
    ]),
    activeTimeout() {
      setTimeout(
        () => (this.isApplyActionAvailable = true),
        this.totalApplyActionTimeoutInMS
      );
    },
    async onApplyFilters() {
      try {
        this.applying = true;

        await this.applyFilters({
          tier: this.tier,
          id: this.id,
        });

        this.displayToast({
          title: this.$t("Success"),
          message: this.$t("Filters Applied"),
          duration: 2000,
          variant: "success",
        });

        this.activeTimeout();
      } catch (err) {
        if (err.response.status === 429) {
          this.isApplyActionAvailable = false;
          this.activeTimeout();

          this.displayToast({
            title: this.$t("Error"),
            message: this.$t("Please give it another try in 5 minutes' time"),
            duration: 3000,
            variant: "danger",
          });
        }
      } finally {
        this.applying = false;
      }
    },
  },
  created() {
    const getLastApprovedFiltersDiffInMS = dayjs().diff(this.filterTime, "ms");

    this.clearSelectedFiltersCache();

    // If the filters been applied less than / or 5 mains ago, set the timer for the remaining of the time
    if (getLastApprovedFiltersDiffInMS <= this.totalApplyActionTimeoutInMS) {
      this.isApplyActionAvailable = false;
      setTimeout(
        () => (this.isApplyActionAvailable = true),
        getLastApprovedFiltersDiffInMS
      );
    }
  },
};
</script>
