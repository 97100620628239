import dayjs from "dayjs";

import { PERIOD } from "@/constants";
import { $http } from "@/plugins/api.js";

const state = {
  cleanMessages: 0,
  spamMessages: 0,
  virusMessages: 0,
  geoblockingReject: 0,
  heloReject: 0,
  invalidRecipients: 0,
  rblReject: 0,
  otherFrontlineReject: 0,
  graphMessages: [],
  selectedPeriod: PERIOD.week,
  fetching: false,
};

export const getters = {
  cleanMessages: (state) => state.cleanMessages,
  spamMessages: (state) => state.spamMessages,
  virusMessages: (state) => state.virusMessages,
  graphMessages: (state) => state.graphMessages,
  geoblockingReject: (state) => state.geoblockingReject,
  heloReject: (state) => state.heloReject,
  invalidRecipients: (state) => state.invalidRecipients,
  rblReject: (state) => state.rblReject,
  otherFrontlineReject: (state) => state.otherFrontlineReject,
  selectedPeriod: (state) => state.selectedPeriod,
  fetching: (state) => state.fetching,
};

const actions = {
  async fetchWeekScanSummary({ commit }, { tier, id }) {
    commit("setFetching", true);
    const searchParams = new URLSearchParams();
    const dateTo = dayjs().format("YYYY-MM-DD");
    const dateFrom = dayjs()
      .subtract(1, PERIOD.week)
      .add(1, "day")
      .format("YYYY-MM-DD");

    searchParams.append("date_from", dateFrom);
    searchParams.append("date_to", dateTo);

    const url =
      !!tier && !!id
        ? `/restapi/${tier}/${id}/stats/scan-summary?${searchParams.toString()}`
        : `/restapi/stats/scan-summary?${searchParams.toString()}`;
    const res = await $http.get(url);

    if (res.status === 200) {
      commit("setCleanMessages", res.data.data.clean_messages.count || 0);
      commit("setSpamMessages", res.data.data.spam_messages.count || 0);
      commit("setVirusMessages", res.data.data.virus_messages.count || 0);
      commit(
        "setGeoblockingReject",
        res.data.data.geoblocking_reject.count || 0
      );
      commit("setHeloReject", res.data.data.helo_reject.count || 0);
      commit(
        "setInvalidRecipients",
        res.data.data.invalid_recipients.count || 0
      );
      commit("setRblReject", res.data.data.rbl_reject.count || 0);
      commit(
        "setOtherFrontlineReject",
        res.data.data.other_frontline_reject.count || 0
      );
      commit("setFetching", false);
    }
  },

  async fetchMonthScanSummary({ commit }, { tier, id }) {
    commit("setFetching", true);
    const searchParams = new URLSearchParams();
    const dateTo = dayjs().format("YYYY-MM-DD");
    const dateFrom = dayjs().subtract(29, PERIOD.day).format("YYYY-MM-DD");

    searchParams.append("date_from", dateFrom);
    searchParams.append("date_to", dateTo);

    const url =
      !!tier && !!id
        ? `/restapi/${tier}/${id}/stats/scan-summary?${searchParams.toString()}`
        : `/restapi/stats/scan-summary?${searchParams.toString()}`;
    const res = await $http.get(url);

    if (res.status === 200) {
      commit("setCleanMessages", res.data.data.clean_messages.count || 0);
      commit("setSpamMessages", res.data.data.spam_messages.count || 0);
      commit("setVirusMessages", res.data.data.virus_messages.count || 0);
      commit(
        "setGeoblockingReject",
        res.data.data.geoblocking_reject.count || 0
      );
      commit("setHeloReject", res.data.data.helo_reject.count || 0);
      commit(
        "setInvalidRecipients",
        res.data.data.invalid_recipients.count || 0
      );
      commit("setRblReject", res.data.data.rbl_reject.count || 0);
      commit(
        "setOtherFrontlineReject",
        res.data.data.other_frontline_reject.count || 0
      );
      commit("setFetching", false);
    }
  },

  async fetchScanGraphSummary({ commit }, { tier, id, period }) {
    let customPeriod = null;
    const searchParams = new URLSearchParams();
    period === PERIOD.month ? (customPeriod = 29) : (customPeriod = 6);
    const dateTo = dayjs().format("YYYY-MM-DD");
    const dateFrom = dayjs()
      .subtract(customPeriod, PERIOD.day)
      .format("YYYY-MM-DD");

    searchParams.append("date_from", dateFrom);
    searchParams.append("date_to", dateTo);

    const url =
      !!tier && !!id
        ? `/restapi/${tier}/${id}/stats/scan-summary/graph?${searchParams.toString()}`
        : `/restapi/stats/scan-summary/graph?${searchParams.toString()}`;
    const res = await $http.get(url);

    if (res.status === 200) {
      commit("setGraphMessages", res.data.data || 0);
    }
  },
};

export const mutations = {
  setCleanMessages: (state, data) => (state.cleanMessages = data),
  setSpamMessages: (state, data) => (state.spamMessages = data),
  setVirusMessages: (state, data) => (state.virusMessages = data),
  setGraphMessages: (state, data) => (state.graphMessages = data),
  setGeoblockingReject: (state, data) => (state.geoblockingReject = data),
  setHeloReject: (state, data) => (state.heloReject = data),
  setInvalidRecipients: (state, data) => (state.invalidRecipients = data),
  setRblReject: (state, data) => (state.rblReject = data),
  setOtherFrontlineReject: (state, data) => (state.otherFrontlineReject = data),
  setSelectedPeriod: (state, data) => (state.selectedPeriod = data),
  setFetching: (state, data) => (state.fetching = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
