import { $http } from "@/plugins/api.js";

const getDefaultState = () => {
  return {
    administrators: [],
    selectedAdministrator: [],
    selectedTier: null,
    selectedTierId: null,
    serverTotal: 0,
  };
};

// initial state
const state = getDefaultState();

const getters = {
  administrators: (state) => state.administrators,
  selectedAdministrator: (state) => state.selectedAdministrator,
  selectedTier: (state) => state.selectedTier,
  selectedTierId: (state) => state.selectedTierId,
  serverTotal: (state) => state.serverTotal,
};

const actions = {
  clearCache({ commit }) {
    commit("resetState");
  },
  async getAdministrators(
    { commit },
    {
      tier,
      id,
      results = 10,
      page = 1,
      sortBy = "updated_at",
      sortDir = "desc",
      filter = null,
    }
  ) {
    let requestURL =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/admins`
        : `/restapi/admins`;

    if (results) requestURL += `?results=${results}`;
    if (page) requestURL += `&page=${page}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    if (filter) requestURL += `&filter=${filter}`;

    const res = await $http.get(requestURL);

    commit("setAdministrators", res.data.data);
    commit("setServerTotal", res.data.total);
  },

  async getAdministrator({ commit }, { tier, tier_id, id }) {
    const requestURL =
      tier !== null && tier_id !== null
        ? `/restapi/${tier}/${tier_id}/admins/${id}`
        : `/restapi/admins/${id}`;

    const res = await $http.get(requestURL);
    commit("setSelectedAdministrator", res.data);
  },

  async createAdministrator({ commit }, { tier, id, formData }) {
    const {
      first_name,
      last_name,
      email,
      password,
      confirm_password,
      linklock,
    } = formData;

    let roles = ["admin"];
    if (linklock) {
      roles.push("link-lock.admin");
    }
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/admins`
        : `/restapi/admins`;
    const res = await $http.post(url, {
      first_name,
      last_name,
      email,
      password,
      confirm_password,
      roles: roles,
    });

    commit("addAdministrator", res.data);
    return res;
  },

  async updateAdministrator({ commit }, { tier, tier_id, id, formData }) {
    const {
      first_name,
      last_name,
      email,
      password,
      confirm_password,
      linklock,
    } = formData;

    let roles = ["admin"];
    if (linklock) {
      roles.push("link-lock.admin");
    }

    const requestURL =
      tier !== null && tier_id !== null
        ? `/restapi/${tier}/${tier_id}/admins/${id}`
        : `/restapi/admins/${id}`;

    const res = await $http.put(requestURL, {
      first_name,
      last_name,
      email,
      password,
      confirm_password,
      roles: roles,
    });
    commit("updateAdministrator", res.data);
  },

  async batchDeleteAdministrators({ commit }, { tier, id, administrators }) {
    const entries = [];

    for (const administrator of administrators) {
      const key = `${administrator.id}`;
      const value = {
        method: "delete",
        path:
          tier !== null && id !== null
            ? `${tier}/${id}/admins/${administrator.id}`
            : `admins/${administrator.id}`,
      };
      entries.push([key, value]);
    }

    const res = await $http.post("/restapi/batch", Object.fromEntries(entries));
    const ids = [];

    for (let [key, value] of Object.entries(res.data)) {
      if (Number(value.code) >= 200 && Number(value.code) <= 299) {
        ids.push(key);
      }
    }
    commit("deleteAdministrators", ids);
    return res;
  },
  clearList({ commit }) {
    commit("setAdministrators", []);
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setAdministrators: (state, data) => (state.administrators = data),
  setSelectedAdministrator: (state, data) =>
    (state.selectedAdministrator = data),
  addAdministrator: (state, data) => {
    state.administrators.push(data);
    state.serverTotal = state.serverTotal + 1;
  },
  updateAdministrator: (state, data) =>
    (state.administrators[`${data.idx}`] = data),
  deleteAdministrators(state, ids) {
    for (const id of ids) {
      state.administrators = state.administrators.filter(
        (administrator) => administrator.id !== Number(id)
      );
      state.serverTotal = state.serverTotal - 1;
    }
  },
  setSelectedTier: (state, data) => (state.selectedTier = data),
  setSelectedTierId: (state, data) => (state.selectedTierId = data),
  setServerTotal: (state, payload) => (state.serverTotal = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
