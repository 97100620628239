import { $http } from "@/plugins/api.js";

const getDefaultState = () => {
  return {
    messageDetails: {},
    messageSource: {},
    messageBody: {},
    host: null,
    loading: true,
    secret_id: null,
    sender: null,
  };
};

// initial state
const state = getDefaultState();

const getters = {
  messageDetails: (state) => state.messageDetails,
  messageSource: (state) => state.messageSource,
  messageBody: (state) => state.messageBody,
  host: (state) => state.host,
  loading: (state) => state.loading,
  secretId: (state) => state.secret_id,
  sender: (state) => state.sender,
};

const actions = {
  async fetchMessageDetails(
    { commit, state },
    { id, source, tier, tier_id, start_date, end_date, recipient }
  ) {
    state.messageDetails = {};
    const host = state.host;
    if (!id) {
      return;
    }

    commit("setLoading", true);

    if (source !== "quarantine" && source !== "history") {
      return;
    }
    let url = `/restapi/${tier}/${tier_id}/${source}/${id}`;
    if (host !== null && source === "quarantine") {
      url = `https://${host}/restapi/${tier}/${tier_id}/${source}/${id}`;
    }
    if (host !== null && source === "history") {
      url = `https://${host}/restapi/${tier}/${tier_id}/${source}/trace?start_date=${start_date}&end_date=${end_date}&recipient=${recipient}&id=${id}`;
    }

    const response = await $http.get(url, {
      headers: {
        Accept: "application/json",
      },
    });
    if (source === "history") {
      commit("setMessageDetails", response.data.data);
    }
    if (source === "quarantine") {
      commit("setMessageDetails", response.data);
    }
    commit("setSecretId", response.data.secret_id);
    commit("setSender", response.data.sender);
    commit("setLoading", false);
    return response.data;
  },
  async fetchMessageBody({ commit, state }, { id, tier, tier_id }) {
    state.messageBody = {};
    const host = state.host;
    if (!id) {
      return;
    }
    commit("setLoading", true);
    let url = `/restapi/${tier}/${tier_id}/quarantine/${id}`;
    if (host !== null) {
      url = `https://${host}/restapi/${tier}/${tier_id}/quarantine/${id}`;
    }
    const response = await $http.get(url, {
      headers: {
        Accept: "text/html",
      },
    });
    commit("setMessageBody", response.data);
    commit("setLoading", false);
  },
  async fetchMessageSource({ commit, state }, { id, tier, tier_id }) {
    state.messageSource = {};
    if (!id) {
      return;
    }
    commit("setLoading", true);
    let url = `/restapi/${tier}/${tier_id}/quarantine/${id}`;
    if (state.host !== null) {
      url = `https://${state.host}/restapi/${tier}/${tier_id}/quarantine/${id}`;
    }
    const response = await $http.get(url, {
      headers: {
        Accept: "message/rfc822",
      },
    });
    commit("setMessageSource", response.data);
    commit("setLoading", false);
  },
  clearHost({ commit }) {
    commit("setHost", null);
  },
  async setHost({ commit }, host) {
    commit("setHost", host);
  },
};

const mutations = {
  setMessageDetails: (state, payload) => (state.messageDetails = payload),
  setSecretId: (state, payload) => (state.secret_id = payload),
  setSender: (state, payload) => (state.sender = payload),
  setMessageSource: (state, payload) => (state.messageSource = payload),
  setMessageBody: (state, payload) => (state.messageBody = payload),
  setHost: (state, payload) => (state.host = payload),
  setLoading: (state, payload) => (state.loading = payload),
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
