import { $http } from "@/plugins/api.js";

const state = {
  users: [],
  selectedUser: [],
  serverTotal: 0,
};

const getters = {
  users: (state) => state.users,
  selectedUser: (state) => state.selectedUser,
  serverTotal: (state) => state.serverTotal,
};

const actions = {
  async fetchUserPolicies(
    { commit },
    {
      tier,
      id,
      params = "additional_data[]=policy",
      results = 10,
      page = 1,
      sortBy = "id",
      sortDir = "desc",
      filter = null,
    }
  ) {
    let url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/users`
        : `/restapi/users`;

    if (results) url += `?results=${results}`;
    if (page) url += `&page=${page}`;
    if (sortBy) url += `&sortkey=${sortBy}`;
    if (sortDir) url += `&sortdir=${sortDir}`;
    if (filter) url += `&filter=${filter}`;
    if (params) url += `&${params}`;

    const res = await $http.get(url);

    commit("setUsers", res.data);
    commit("setServerTotal", res.data.total);
  },

  async fetchUser({ commit }, { userId, domainId = null }) {
    const url =
      domainId === null
        ? `/restapi/users/${userId}`
        : `/restapi/domains/${domainId}/users/${userId}`;
    const res = await $http.get(url);

    commit("setSelectedUser", res.data);
  },

  async updateUser({ commit }, { id, value }) {
    const res = await $http.put(`/restapi/users/${id}`, value);
    commit("updateUserPolicy", res.data);
  },

  async createUserPolicy({ commit }, { tier, id, formData }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/users`
        : `/restapi/users`;
    const res = await $http.post(url, formData);
    commit("addUser", res.data);
    return res.data;
  },

  async updateUserPolicy({ commit }, { id, value }) {
    const {
      archive_clean_mail,
      banned,
      geoblocking,
      locked,
      policy_name,
      qreport,
      spam,
      virus,
    } = value;

    const payload = {
      archive_clean_mail,
      banned,
      ...(geoblocking && { geoblocking: geoblocking }),
      ...(locked && { locked: locked }),
      policy_name,
      qreport,
      spam,
      virus,
    };

    const url = `/restapi/users/${id}/policy`;
    const res = await $http.put(url, payload);

    commit("updateUserPolicy", res.data);
    return res.data;
  },

  async batchDeleteUserPolicies({ commit }, { users, tier, tier_id }) {
    const entries = [];

    for (const user of users) {
      const key = `${user.id}`;
      const value = {
        method: "delete",
        path: `${tier}/${tier_id}/users/${user.id}`,
      };

      entries.push([key, value]);
      state.serverTotal = state.serverTotal - 1;
    }

    const res = await $http.post("/restapi/batch", Object.fromEntries(entries));
    const ids = Object.keys(res.data).map((item) => +item);
    commit("deleteUserPolicies", ids);
  },

  clearList({ commit }) {
    commit("setUsers", []);
    commit("setSelectedUser", []);
  },
};

const mutations = {
  setUsers: (state, data) => (state.users = data.data),
  addUser: (state, data) => {
    state.users.push(data);
    state.serverTotal = state.serverTotal + 1;
  },
  setSelectedUser: (state, data) => (state.selectedUser = data),
  updateUserPolicy: (state, data) => (state.users[`${data.idx}`] = data.users),
  deleteUserPolicies(state, ids) {
    for (const id of ids) {
      state.users = state.users.filter((user) => user.id !== id);
    }
    state.serverTotal = state.serverTotal - 1;
  },
  setServerTotal: (state, payload) => (state.serverTotal = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
