import authentication from "@/store/modules/authentication";
import fileUpload from "@/store/modules/fileUpload";
import geoBlocking from "@/store/modules/geoBlocking";
import geoBlockingRules from "@/store/modules/geoBlockingRules";
import geoBlockingExemptions from "@/store/modules/geoBlockingExemptions";
import userInterface from "@/store/modules/userInterface";
import linkLock from "@/store/modules/linkLock";
import modal from "@/store/modules/modal";
import msps from "@/store/modules/msps";
import customers from "@/store/modules/customers";
import pageMeta from "@/store/modules/pageMeta";
import support from "@/store/modules/support";
import toast from "@/store/modules/toast";
import userPrefs from "@/store/modules/userPrefs";
import users from "@/store/modules/users";
import quarantinedMessages from "@/store/modules/quarantinedMessages";
import emailsHistory from "@/store/modules/emailsHistory";
import domains from "@/store/modules/domains";
import domainPolicy from "@/store/modules/domainPolicy";
import patternFilters from "./patternFilters";
import userPolicies from "./userPolicies";
import blockAllowEmailsList from "./blockAllowEmailsList";
import blockAllowDomainsList from "./blockAllowDomainsList";
import blockAllowIPsList from "./blockAllowIPsList";
import scanSummary from "./scanSummary";
import message from "./message";
import passwordReset from "./passwordReset";
import antiSpoofing from "./antispoofing";
import administrators from "./administrators";
import attachmentFilters from "./attachmentFilters";
import scheduledReports from "./scheduledReports";
import onDemandReports from "./onDemandReports";
import archiveReports from "./archiveReports";

export default {
  authentication,
  fileUpload,
  geoBlocking,
  geoBlockingExemptions,
  geoBlockingRules,
  userInterface,
  linkLock,
  modal,
  msps,
  customers,
  pageMeta,
  support,
  toast,
  userPrefs,
  users,
  quarantinedMessages,
  emailsHistory,
  domains,
  domainPolicy,
  patternFilters,
  userPolicies,
  blockAllowEmailsList,
  blockAllowDomainsList,
  blockAllowIPsList,
  scanSummary,
  message,
  passwordReset,
  antiSpoofing,
  administrators,
  attachmentFilters,
  scheduledReports,
  onDemandReports,
  archiveReports,
};
