import { $http } from "@/plugins/api.js";

const getDefaultState = () => {
  return {
    domains: [],
    serverTotal: 0,
  };
};

// initial state
const state = getDefaultState();

export const getters = {
  domains: (state) => state.domains,
  serverTotal: (state) => state.serverTotal,
};

export const actions = {
  clearDomainsCache({ commit }) {
    commit("resetState");
  },
  async fetchBlockAllowDomains(
    { commit },
    {
      tier,
      id,
      results = 10,
      page = 1,
      sortBy = "sender",
      sortDir = "desc",
      filter = null,
    }
  ) {
    const params = "type=domain";

    let requestURL =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/allow-block-list?${params}`
        : `/restapi/allow-block-list?${params}`;

    if (results) requestURL += `&results=${results}`;
    if (page) requestURL += `&page=${page}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    if (filter) requestURL += `&filter=${filter}`;

    const result = await $http.get(requestURL);

    commit("setDomains", result.data.data);
    commit("setServerTotal", result.data.total);
  },
  async blockDomain({ commit }, { entry, tier, id }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/block-list`
        : `/restapi/block-list`;
    const res = await $http.post(url, entry);

    commit("addDomain", res.data);
    return res;
  },
  async updateBlockedDomain(_context, { tier, id, formData, domainId }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/block-list/${domainId}`
        : `/restapi/block-list/${domainId}`;
    const res = await $http.put(url, formData);

    return res;
  },
  async allowDomain({ commit }, { entry, tier, id }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/allow-list`
        : `/restapi/allow-list`;
    const res = await $http.post(url, entry);

    commit("addDomain", res.data);
    return res;
  },
  async updateAllowedDomain(_context, { tier, id, formData, domainId }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/allow-list/${domainId}`
        : `/restapi/allow-list/${domainId}`;
    const res = await $http.put(url, formData);

    return res;
  },
  async batchDeleteDomains({ commit }, { domains, tier, id }) {
    const reqAllowedEntries = [];
    const reqBlockedEntries = [];

    for (const domain of domains) {
      if (domain.status.toUpperCase() === "BLOCKED") {
        const key = `${domain.id}`;
        const value = {
          method: "delete",
          path:
            tier !== null && id !== null
              ? `${tier}/${id}/block-list/${domain.id}`
              : `block-list/${domain.id}`,
        };

        reqBlockedEntries.push([key, value]);
      } else {
        const key = `${domain.id}`;
        const value = {
          method: "delete",
          path:
            tier !== null && id !== null
              ? `${tier}/${id}/allow-list/${domain.id}`
              : `allow-list/${domain.id}`,
        };

        reqAllowedEntries.push([key, value]);
      }
    }

    if (reqAllowedEntries.length > 0) {
      const res = await $http.post(
        "/restapi/batch",
        Object.fromEntries(reqAllowedEntries)
      );
      const ids = Object.keys(res.data).map((item) => +item);

      commit("deleteDomains", ids);
    }

    if (reqBlockedEntries.length > 0) {
      const res = await $http.post(
        "/restapi/batch",
        Object.fromEntries(reqBlockedEntries)
      );
      const ids = Object.keys(res.data).map((item) => +item);

      commit("deleteDomains", ids);
    }
  },
};

export const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setDomains: (state, data) => (state.domains = data),
  setServerTotal: (state, payload) => (state.serverTotal = payload),
  deleteDomains(state, ids) {
    for (const id of ids) {
      state.domains = state.domains.filter(
        (email) => Number(email.id) !== Number(id)
      );
      state.serverTotal = state.serverTotal - 1;
    }
  },
  addDomain: (state, data) => {
    state.domains.push(data);
    state.serverTotal = state.serverTotal + 1;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
