import { $http } from "@/plugins/api.js";

const getDefaultState = () => {
  return {
    policy: {},
    exemptions: [],
    serverTotal: 0,
  };
};

const state = getDefaultState();

const getters = {
  policy: (state) => state.policy,
  linkLockStatus: (state) => {
    // If the Policy is exist return the status
    if (Object.hasOwn(state.policy, "enabled")) {
      return state.policy.enabled;
    }
    // Otherwise null, as not exist
    return null;
  },
  inheritedStatus: (state) => {
    if (state.policy.metadata) {
      const inherited = state.policy.metadata.exists ? false : true;
      return inherited;
    }
  },
  exemptions: (state) => state.exemptions,
  serverTotal: (state) => state.serverTotal,
};

const actions = {
  clearCache({ commit }) {
    commit("resetState");
  },
  async fetchLinkLockPolicy({ commit }, { tier, id }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/link-lock`
        : `/restapi/link-lock`;
    const res = await $http.get(url);

    commit("setLinkLockPolicy", res.data.data);
  },
  async createLinkLockPolicy({ commit }, { tier, id, formData }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/link-lock`
        : `/restapi/link-lock`;
    const res = await $http.post(url, formData);

    commit("setLinkLockPolicy", res.data.data);
  },
  async updateLinkLockPolicy({ commit }, { tier, id, formData }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/link-lock`
        : `/restapi/link-lock`;
    const res = await $http.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    commit("setLinkLockPolicy", res.data.data);
  },

  async fetchLinkLockExemptions(
    { commit },
    {
      tier,
      id,
      results = 10,
      page = 1,
      sortBy = "updated_at",
      sortDir = "desc",
      filter = null,
    }
  ) {
    let requestURL =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/link-lock/allowed-urls`
        : `/restapi/link-lock/allowed-urls`;

    if (results) requestURL += `?results=${results}`;
    if (page) requestURL += `&page=${page}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    if (filter) requestURL += `&filter=${filter}`;

    const res = await $http.get(requestURL);

    commit("setLinkLockExemptions", res.data.data);
    commit("setServerTotal", res.data.total);
  },
  async createLinkLockExemption({ commit }, { tier, id, formData }) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/link-lock/allowed-urls`
        : `/restapi/link-lock/allowed-urls`;
    const res = await $http.post(url, formData);

    commit("addLinkLockExemption", res.data.data);
  },
  async updateLinkLockExemption(
    { commit },
    { tier, id, exemptionId, formData }
  ) {
    const url =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/link-lock/allowed-urls/${exemptionId}`
        : `/restapi/link-lock/allowed-urls/${exemptionId}`;
    const res = await $http.patch(url, formData);

    commit("updateLinkLockExemption", res.data.data);
  },
  async batchDeleteLinkLockExemptions({ commit }, { tier, id, exemptions }) {
    const entries = [];

    for (const exemption of exemptions) {
      const key = `${exemption.id}`;
      const value = {
        method: "delete",
        path:
          tier !== null && id !== null
            ? `${tier}/${id}/link-lock/allowed-urls/${exemption.id}`
            : `/link-lock/allowed-urls/${exemption.id}`,
      };

      entries.push([key, value]);
    }

    const res = await $http.post("/restapi/batch", Object.fromEntries(entries));

    const ids = Object.keys(res.data).map((id) => id);

    commit("deleteExemptions", ids);
  },

  clearList({ commit }) {
    commit("setLinkLockExemptions", []);
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setLinkLockPolicy: (state, data) => (state.policy = data),
  setLinkLockExemptions: (state, data) => (state.exemptions = data),
  addLinkLockExemption: (state, data) => {
    state.exemptions.push(data);
    state.serverTotal = state.serverTotal + 1;
  },
  updateLinkLockExemption: (state, data) => {
    const index = state.exemptions.findIndex(
      (exemption) => Number(exemption.id) === Number(data.id)
    );

    if (index !== -1) {
      state.exemptions[index] = data;
    }
  },
  deleteExemptions(state, ids) {
    for (const id of ids) {
      state.exemptions = state.exemptions.filter(
        (exemption) => exemption.id !== Number(id)
      );
      state.serverTotal = state.serverTotal - 1;
    }
  },
  setServerTotal: (state, payload) => (state.serverTotal = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
