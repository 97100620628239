import { $http } from "@/plugins/api.js";

const state = {
  support: {
    email: null,
    link: null,
  },
  userLinks: [],
};

const getters = {
  support(state) {
    return state.support;
  },
  editSupport(state) {
    return state.editSupport;
  },
  userLinks(state) {
    return state.userLinks;
  },
};

const actions = {
  async fetchSupport({ commit }, { type_id = null, type = null }) {
    let url = null;
    if (type === "msps") {
      url = `/restapi/${type}/${type_id}/ui/support?own=true`;
    } else {
      url = `/restapi/${type}/${type_id}/ui/support`;
    }
    const res = await $http.get(
      type_id !== null && type !== null && url !== null
        ? url
        : "/restapi/ui/support"
    );
    commit("updateSupport", res.data);
  },
  async updateSupport({ commit }, { support, type_id = null, type = null }) {
    const res = await $http.post(
      type_id === null && type === null
        ? "/restapi/ui/support"
        : `/restapi/${type}/${type_id}/ui/support`,
      support
    );
    commit("updateSupport", res.data);
  },
  setUserLinks({ commit }, value) {
    commit("setUserLinks", value);
  },
  updateUserLinks({ commit }, value) {
    commit("updateUserLinks", value);
  },
};

const mutations = {
  updateSupport(state, { email, link }) {
    state.support = {
      email,
      link,
    };
  },
  updateUserLinks: (state, value) =>
    (state.userLinks = [...state.userLinks, ...value]),
  setUserLinks: (state, payload) => (state.userLinks = payload),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
