const userRoutes = [
  {
    path: "",
    redirect: "/user/quarantine",
  },
  {
    path: "history",
    name: "user-history",
    component: () => import("@/views/EndUser/History/index.vue"),
  },
  {
    path: "history/message_overview",
    redirect: {
      name: "user-history",
    },
  },
  {
    path: "history/message_overview/:id",
    component: () =>
      import("@/views/EndUser/History/MessageOverview/index.vue"),
    name: "user-history-message-overview",
    redirect: {
      name: "user-history-message-overview-details",
    },
    children: [
      {
        path: "details",
        name: "user-history-message-overview-details",
        component: () =>
          import("@/views/EndUser/History/MessageOverview/Tabs/Details.vue"),
      },
      {
        path: "message",
        name: "user-history-message-overview-message",
        component: () =>
          import("@/views/EndUser/History/MessageOverview/Tabs/Message.vue"),
      },
      {
        path: "source",
        name: "user-history-message-overview-source",
        component: () =>
          import("@/views/EndUser/History/MessageOverview/Tabs/Source.vue"),
      },
    ],
  },
  {
    path: "quarantine",
    component: () => import("@/views/EndUser/Quarantine"),
    name: "user-quarantine",
  },
  {
    path: "quarantine/message_overview",
    name: "user-quarantine-message",
    redirect: {
      name: "user-quarantine",
    },
  },
  {
    path: "quarantine/message_overview/:id",
    component: () =>
      import("@/views/EndUser/Quarantine/MessageOverview/index.vue"),
    name: "user-quarantine-message-overview",
    redirect: {
      name: "user-quarantine-message-overview-details",
    },
    children: [
      {
        path: "details",
        name: "user-quarantine-message-overview-details",
        component: () =>
          import("@/views/EndUser/Quarantine/MessageOverview/Tabs/Details.vue"),
      },
      {
        path: "message",
        name: "user-quarantine-message-overview-message",
        component: () =>
          import("@/views/EndUser/Quarantine/MessageOverview/Tabs/Message.vue"),
      },
      {
        path: "source",
        name: "user-quarantine-message-overview-source",
        component: () =>
          import("@/views/EndUser/Quarantine/MessageOverview/Tabs/Source.vue"),
      },
    ],
  },
  {
    path: "filtering",
    redirect: {
      name: "user-block-allow-list",
    },
    component: () => import("@/views/EndUser/ContentFiltering"),
    children: [
      {
        path: "attachments",
        name: "user-filtering-attachments",
        component: () => import("@/views/EndUser/ContentFiltering/Attachments"),
        redirect: {
          name: "user-filtering-attachments-extension",
        },
        children: [
          {
            path: "extension_filters",
            name: "user-filtering-attachments-extension",
            component: () =>
              import(
                "@/views/EndUser/ContentFiltering/Attachments/Filters/Extension.vue"
              ),
          },
          {
            path: "file_name_filters",
            name: "user-filtering-attachments-filename",
            component: () =>
              import(
                "@/views/EndUser/ContentFiltering/Attachments/Filters/FileName.vue"
              ),
          },
          {
            path: "file_type_filters",
            name: "user-filtering-attachments-filetype",
            component: () =>
              import(
                "@/views/EndUser/ContentFiltering/Attachments/Filters/FileType.vue"
              ),
          },
          {
            path: "mime_type_filters",
            name: "user-filtering-attachments-mimetype",
            component: () =>
              import(
                "@/views/EndUser/ContentFiltering/Attachments/Filters/MimeType.vue"
              ),
          },
          {
            path: "password_protected_archive",
            name: "user-filtering-attachments-ppa",
            component: () =>
              import(
                "@/views/EndUser/ContentFiltering/Attachments/Filters/PasswordProtectedArchive.vue"
              ),
          },
        ],
      },
      {
        path: "attachments/edit/:id",
        name: "user-filtering-attachments-edit",
        component: () =>
          import(
            "@/views/EndUser/ContentFiltering/Attachments/Filters/Edit/index.vue"
          ),
      },
      {
        path: "geoblocking",
        name: "user-geoblocking",
        component: () =>
          import("@/views/EndUser/ContentFiltering/Geoblocking/index.vue"),
        redirect: {
          name: "user-geoblocking-rule",
        },
        children: [
          {
            path: "rules",
            name: "user-geoblocking-rule",
            component: () =>
              import("@/views/EndUser/ContentFiltering/Geoblocking/Tabs/Rules"),
          },
          {
            path: "exemptions",
            name: "user-geoblocking-exemptions",
            component: () =>
              import(
                "@/views/EndUser/ContentFiltering/Geoblocking/Tabs/Exemptions"
              ),
          },
        ],
      },
      {
        path: "geoblocking/rules/:iso",
        name: "user-geoblocking-rule-edit",
        component: () =>
          import(
            "@/views/EndUser/ContentFiltering/Geoblocking/Tabs/Rules/Edit"
          ),
      },
      {
        path: "geoblocking/exemptions/:id",
        name: "user-geoblocking-exemptions-edit",
        component: () =>
          import(
            "@/views/EndUser/ContentFiltering/Geoblocking/Tabs/Exemptions/Edit"
          ),
      },
      {
        path: "allow_block_list",
        name: "user-block-allow-list",
        component: () =>
          import("@/views/EndUser/ContentFiltering/BlockAllowList/index.vue"),
        redirect: {
          name: "user-block-allow-list-emails",
        },
        children: [
          {
            path: "emails",
            name: "user-block-allow-list-emails",
            component: () =>
              import(
                "@/views/EndUser/ContentFiltering/BlockAllowList/Emails/index.vue"
              ),
          },
          {
            path: "domains",
            name: "user-block-allow-list-domains",
            component: () =>
              import(
                "@/views/EndUser/ContentFiltering/BlockAllowList/Domains/index.vue"
              ),
          },
        ],
      },
      {
        path: "allow_block_list/emails/:id",
        name: "user-block-allow-list-emails-edit",
        component: () =>
          import("@/views/EndUser/ContentFiltering/BlockAllowList/Emails/Edit"),
      },
      {
        path: "allow_block_list/domains/:id",
        name: "user-block-allow-list-domains-edit",
        component: () =>
          import(
            "@/views/EndUser/ContentFiltering/BlockAllowList/Domains/Edit"
          ),
      },
      {
        path: "link_lock",
        name: "user-link-lock",
        component: () => import("@/views/EndUser/ContentFiltering/LinkLock"),
        children: [
          {
            path: "policy_configuration",
            name: "user-link-lock-policy-configuration",
            component: () =>
              import(
                "@/views/EndUser/ContentFiltering/LinkLock/Tabs/PolicyConfiguration/index.vue"
              ),
          },
          {
            path: "exemptions",
            name: "user-link-lock-exemptions",
            component: () =>
              import(
                "@/views/EndUser/ContentFiltering/LinkLock/Tabs/Exemptions/index.vue"
              ),
          },
        ],
      },
      {
        path: "link_lock/exemptions/:id",
        name: "user-link-lock-exemptions-edit",
        component: () =>
          import(
            "@/views/EndUser/ContentFiltering/LinkLock/Tabs/Exemptions/Edit/index.vue"
          ),
      },
    ],
  },
];

export default userRoutes;
