const profileRoutes = [
  {
    path: "",
    redirect: {
      name: "profileSecurity",
    },
  },
  {
    path: "security",
    name: "profileSecurity",
    component: () => import("@/views/Profile/Security"),
  },
];

export default profileRoutes;
