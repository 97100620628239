import { $http } from "@/plugins/api.js";

const state = {
  email: "",
};

const getters = {
  email: (state) => state.email,
};

const actions = {
  async requestResetLink({ commit }, { email, domain }) {
    commit("setEmail", email);

    const res = await $http.post("/restapi/password/forgot", {
      domain,
      email,
    });

    return res;
  },
  async setNewPassword(_context, { email, password, confirm_password, token }) {
    return await $http.post(`/restapi/password/reset?token=${token}`, {
      email,
      password,
      confirm_password,
    });
  },
};

const mutations = {
  setEmail: (state, data) => (state.email = data),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
