<template>
  <div class="tw-h-screen">
    <router-view />
    <vue3-progress-bar />
    <t-toasts />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import timezone from "dayjs/plugin/timezone";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import TToasts from "./components/TToasts";
import { useTheme } from "@/composables/useTheme";

dayjs.extend(utc);
dayjs.extend(timezone);

import "@/scss/app.scss";

export default {
  components: {
    TToasts,
  },
  setup() {
    useTheme();
  },
  computed: {
    ...mapGetters("authentication", ["token"]),
  },
  methods: {
    ...mapActions("userPrefs", ["setUserPrefLang", "setUserPrefTimezone"]),
  },
  async created() {
    const lang = navigator.language;
    const timezone = dayjs.tz.guess();

    this.$root.$i18n.locale = lang;
    this.setUserPrefLang(lang);

    this.$root.$i18n.timeZoneName = timezone;
    this.setUserPrefTimezone(timezone);

    this.$http.setAuthorisationToken(this.token?.access_token);
  },
};
</script>
