import { v4 as uuid } from "uuid";

const state = {
  toasts: [],
  timeoutIDs: {},
};

const getters = {
  toasts: (state) => state.toasts,
};

const actions = {
  displayToast({ commit }, toast) {
    toast.id = uuid();

    commit("showToast", toast);

    toast.timeoutID = toast.duration
      ? setTimeout(() => {
          commit("closeToast", toast.id);
        }, toast.duration)
      : null;

    commit("updateTimeoutID", toast.id);
  },

  closeToast({ commit, state }, toastId) {
    state.timeoutIDs[`${toastId}`] && clearTimeout(state.timeoutIDs[toastId]);

    commit("closeToast", toastId);
  },
};

const mutations = {
  showToast: (state, toast) => {
    state.toasts.push(toast);
  },
  closeToast: (state, toastId) => {
    state.toasts = state.toasts.filter((t) => t.id !== toastId);
    delete state.timeoutIDs[`${toastId}`];
  },
  updateTimeoutID: (state, toast) =>
    (state.timeoutIDs[`${toast.id}`] = toast.timeoutID),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
