import { $http } from "@/plugins/api.js";
import { CURRENT_DATE } from "@/constants";

export const initialFiltersState = {
  start_date: CURRENT_DATE,
  end_date: CURRENT_DATE,
  sender: "",
  recipient: "",
  domain: "@.",
  domain_group: "@.",
  subject: "",
  type: "all", // message_type
  score_compare: "*",
  score: ["", ""],
  cluster: 1,
  date_range: null,
};

const state = {
  messages: [],
  selectedQuarantine: null,
  filters: {
    ...initialFiltersState,
  },
  serverTotal: 0,
  counter: 0,
};

const getters = {
  messages: (state) => state.messages,
  selectedQuarantine: (state) => state.selectedQuarantine,
  serverTotal: (state) => state.serverTotal,
  counter: (state) => state.counter,
  filters: (state) => state.filters,
};

const actions = {
  setSelectedQuarantine({ commit }, email) {
    commit("setSelectedQuarantine", email);
  },

  async releaseQuarantinedMessage(
    { _ },
    { id, formData, tier, tier_id, host = null }
  ) {
    let url = `/restapi/${tier}/${tier_id}/quarantine/${id}`;
    if (host !== null) {
      url = `https://${host}/restapi/${tier}/${tier_id}/quarantine/${id}`;
    }
    const response = await $http({
      method: "post",
      url,
      data: formData,
    });
    return response.data;
  },
  async batchReleaseQuarantineMessage({ commit }, { tier, id, messages }) {
    const hostMap = new Map();
    messages.forEach((message) => {
      const host = message.host;
      if (!hostMap.has(host)) {
        hostMap.set(host, []);
      }
      const key = `${message.id}`;
      const value = {
        method: "post",
        path: `${tier}/${id}/quarantine/${message.id}`,
        parameters: { secret_id: message.secret_id },
      };
      hostMap.get(host).push([key, value]);
    });

    const promises = [];
    for (const [host, entries] of hostMap.entries()) {
      let url = "/restapi/batch";
      if (host) {
        url = `https://${host}/restapi/batch`;
      }

      if (entries.length === 0) {
        return;
      }

      const promise = $http.post(url, Object.fromEntries(entries));
      promises.push(promise);
    }

    const responses = await Promise.all(promises);
    const ids = responses.flatMap((res) =>
      Object.keys(res.data).map((item) => +item)
    );
    commit("deleteQuarantinedMessages", ids);
  },

  async allowQuarantinedMessage(
    { _ },
    { id, formData, tier, tier_id, host = null }
  ) {
    let url = `/restapi/${tier}/${tier_id}/quarantine/${id}/allow`;
    if (host !== null) {
      url = `https://${host}/restapi/${tier}/${tier_id}/quarantine/${id}/allow`;
    }
    const response = await $http({
      method: "post",
      url,
      data: formData,
    });
    return response.data;
  },
  async batchAllowQuarantineMessage({ commit }, { tier, id, messages }) {
    const hostMap = new Map();
    messages.forEach((message) => {
      const host = message.host;
      if (!hostMap.has(host)) {
        hostMap.set(host, []);
      }
      const key = `${message.id}`;
      const value = {
        method: "post",
        path: `${tier}/${id}/quarantine/${message.id}/allow`,
        parameters: { secret_id: message.secret_id },
      };
      hostMap.get(host).push([key, value]);
    });

    const promises = [];
    for (const [host, entries] of hostMap.entries()) {
      let url = "/restapi/batch";
      if (host) {
        url = `https://${host}/restapi/batch`;
      }

      if (entries.length === 0) {
        return;
      }

      const promise = $http.post(url, Object.fromEntries(entries));
      promises.push(promise);
    }

    const responses = await Promise.all(promises);
    const ids = responses.flatMap((res) =>
      Object.keys(res.data).map((item) => +item)
    );
    commit("deleteQuarantinedMessages", ids);
  },

  async fetchQuarantinedMessages(
    { commit, state },
    {
      tier,
      id,
      results = 10,
      page = 1,
      sortBy = "message_received",
      sortDir = "desc",
    }
  ) {
    const searchParams = new URLSearchParams();
    const filtersState = JSON.parse(JSON.stringify(state.filters));

    for (const [key, value] of Object.entries(filtersState)) {
      if (!value) {
        delete filtersState[key];
      }

      // score_compare & score comes together, so remove "score" if no "score_compare"
      if (key === "score" && !filtersState.score_compare) {
        delete filtersState[key];
      }
    }

    if (
      filtersState.score_compare &&
      filtersState.score_compare !== "between"
    ) {
      const score = filtersState.score.shift();
      filtersState.score = score;
    }

    if (filtersState.domain === "@.") {
      delete filtersState["domain"];
    }

    if (filtersState.domain_group === "@.") {
      delete filtersState["domain_group"];
    }

    // if recipient is empty (mandatory from API)
    if (filtersState.recipient === undefined) {
      filtersState.recipient = "*@*";
    }

    // if score_compare is *, it should be removed from the request
    if (filtersState.score_compare === "*") {
      delete filtersState["score"];
      delete filtersState["score_compare"];
    }

    for (const [key, value] of Object.entries(filtersState)) {
      searchParams.append(key, value);
    }

    let requestURL =
      tier !== null && id !== null
        ? `/restapi/${tier}/${id}/quarantine?${searchParams.toString()}`
        : `/restapi/quarantine?${searchParams.toString()}`;
    if (results) requestURL += `&results=${results}`;
    if (page) requestURL += `&page=${page}`;
    if (sortBy) requestURL += `&sortkey=${sortBy}`;
    if (sortDir) requestURL += `&sortdir=${sortDir}`;
    const response = await $http.get(requestURL);

    commit("setQuarantinedMessages", response.data.data);
    commit("setServerTotal", response.data.total);
    commit("setCounter", response.data.count);
  },
  async deleteQuarantinedMessage(
    { commit },
    { id, secret_id, tier, tier_id, host }
  ) {
    let tierUrl = `/restapi/${tier}/${tier_id}/quarantine/${id}`;
    let requestUrl = tierUrl;
    if (host !== null) {
      requestUrl = `https://${host}` + tierUrl;
    }
    const response = await $http({
      method: "delete",
      url: requestUrl,
      data: { secret_id },
    });

    commit("setQuarantinedMessages", response.data.data);
    return response.data;
  },
  async batchDeleteQuarantinedMessages({ commit }, { messages, tier, id }) {
    // I use a Map called hostMap to group the entries by host.
    const hostMap = new Map();

    messages.forEach((message) => {
      const host = message.host;
      if (!hostMap.has(host)) {
        hostMap.set(host, []);
      }

      const key = `${message.id}`;
      const value = {
        method: "delete",
        path: `${tier}/${id}/quarantine/${message.id}`,
        parameters: { secret_id: message.secret_id },
      };
      // The loop iterates through the messages, and for each message, it adds the corresponding entry to the appropriate host in the hostMap.
      hostMap.get(host).push([key, value]);
    });

    const promises = [];
    // Then, I iterate through the hostMap and make a separate $http.post request for each unique host.
    for (const [host, entries] of hostMap.entries()) {
      let url = "/restapi/batch";
      if (host) {
        url = `https://${host}/restapi/batch`;
      }

      const promise = $http.post(url, Object.fromEntries(entries));
      promises.push(promise);
    }
    // Finally, I use Promise.all to wait for all the requests to complete and collect their responses.
    const responses = await Promise.all(promises);

    const ids = responses.flatMap((res) =>
      Object.keys(res.data).map((item) => +item)
    );
    commit("deleteQuarantinedMessages", ids);
  },
  clearSearch({ commit, state }, isEndUser) {
    const resetFilters = {
      ...initialFiltersState,
      recipient: isEndUser
        ? state.filters.recipient
        : initialFiltersState.recipient,
    };
    commit("setFilters", resetFilters);
  },
  clearEmailsList({ commit }) {
    commit("setQuarantinedMessages", []);
  },
  setNewFilters({ commit }, newFilters) {
    commit("setFilters", newFilters);
  },
};

const mutations = {
  setSelectedQuarantine: (state, payload) =>
    (state.selectedQuarantine = payload),
  setQuarantinedMessages: (state, data) => (state.messages = data),
  setServerTotal: (state, data) => (state.serverTotal = data),
  setCounter: (state, data) => (state.counter = data),
  setFilters: (state, data) => (state.filters = data),
  setDateRange: (state, data) => (state.filters.date_range = data),
  setStartDate: (state, data) => (state.filters.start_date = data),
  setEndDate: (state, data) => (state.filters.end_date = data),
  setSender: (state, data) => (state.filters.sender = data),
  setRecipient: (state, data) => (state.filters.recipient = data),
  setSubject: (state, data) => (state.filters.subject = data),
  setMessageType: (state, data) => (state.filters.type = data),
  setScoreCompare: (state, data) => (state.filters.score_compare = data),
  setScore: (state, data) => (state.filters.score = data),
  setDomain: (state, data) => (state.filters.domain = data),
  setDomainGroup: (state, data) => (state.filters.domain_group = data),
  deleteQuarantinedMessages(state, ids) {
    for (const id of ids) {
      state.messages = state.messages.filter((message) => message.id !== id);
      state.serverTotal = state.serverTotal - 1;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
